import React, { useEffect } from "react"
import { connect } from "react-redux"
import Modal from "components/Modal"
import { redirectToUri, useUri, Path } from "utils/router"
import { Container, Typography } from "@material-ui/core"
import { colors } from "../../Theme/colors"
import styled from "styled-components"
import t from "i18n"
import QuotationForm from "./QuotationForm"
import QuotationNavigation from "./QuotationNavigation"
import QuotationFooter from "./QuotationFooter"
import QuotationBevList from "./QuotationBevList"
import { getBevQuotation } from "ducks/bevQuotation/actions"

const ContentContainer = styled(Container)`
  background-color: ${colors.white};
  height: 90%;
  padding: 3.5vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  &:focus {
    outline: none;
  }
`

const QuotationHeader = () => (
  <Typography variant="h6">{t("correctionTitle")}</Typography>
)

const WineQuotationModal = ({ getBevQuotation, currentQuotationName }) => {
  const uriState = useUri()
  const { currentQuotationId, bevQuotationId } = uriState

  useEffect(() => {
    if (currentQuotationName)
      getBevQuotation({ currentQuotationId, bevQuotationId })
  }, [
    getBevQuotation,
    currentQuotationId,
    bevQuotationId,
    currentQuotationName,
  ])

  return (
    <Modal
      open
      onClose={() =>
        redirectToUri({
          path: Path.quotationResult,
          ...uriState,
        })
      }
    >
      <ContentContainer maxWidth="lg">
        <QuotationHeader />
        <QuotationNavigation
          currentQuotationId={currentQuotationId}
          bevQuotationId={bevQuotationId}
        />
        <QuotationForm bevQuotationId={bevQuotationId} />
        <QuotationBevList bevQuotationId={bevQuotationId} />
        <QuotationFooter
          currentQuotationId={currentQuotationId}
          bevQuotationId={bevQuotationId}
        />
      </ContentContainer>
    </Modal>
  )
}

export default connect(
  state => ({ currentQuotationName: state.currentQuotation.name }),
  { getBevQuotation },
)(WineQuotationModal)
