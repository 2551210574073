import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import { selectSearchedBevProductData } from "./actions"
import {
  selectSearchedBevProductDataHandler,
  setProductDataReducerFactory,
} from "../../utils/bevProductData"
import { setBevProductData } from "./actions"

const {
  reducer,
  actions: { modifyBevQuotationVintage },
} = createSlice({
  name: "vintage",
  initialState: null,
  reducers: {
    modifyBevQuotationVintage: {
      reducer: _.identity,
      prepare: ({ bevQuotation, vintage, currentQuotationId }) => {
        const { bevProductData } = bevQuotation

        return {
          payload: {
            currentQuotationId,
            bevQuotation: {
              ...bevQuotation,
              bevProductData: {
                ...bevProductData,
                vintage,
              },
            },
          },
        }
      },
    },
  },
  extraReducers: {
    [selectSearchedBevProductData]: selectSearchedBevProductDataHandler(
      "vintage",
    ),
    [setBevProductData]: setProductDataReducerFactory("vintage"),
  },
})

export { modifyBevQuotationVintage }

export default reducer
