import { createSlice } from "@reduxjs/toolkit"
import {
  closeFieldListhandler,
  debounceGetFieldList,
  getFieldListErrorHandler,
  getFieldListHandler,
  getFieldListSuccess,
  openFieldListHandler,
} from "utils/fieldUtils"

const initialState = {
  isLoading: false,
  list: [],
  isOpen: false,
}

const {
  reducer,
  actions: {
    getDomainListError,
    getDomainListSuccess,
    closeDomainList,
    getDomainList: getDomainListAction,
    openDomainList,
  },
} = createSlice({
  name: "domain",
  initialState,
  reducers: {
    getDomainList: getFieldListHandler,
    getDomainListError: getFieldListErrorHandler,
    getDomainListSuccess: getFieldListSuccess("domainList"),
    closeDomainList: closeFieldListhandler,
    openDomainList: openFieldListHandler,
  },
})

export const getDomainList = debounceGetFieldList(getDomainListAction)

export {
  getDomainListError,
  getDomainListSuccess,
  closeDomainList,
  openDomainList,
  getDomainListAction,
}

export default reducer
