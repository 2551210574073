import { createSlice } from "@reduxjs/toolkit"
import { authenticateSuccess, bypassAuthentication } from "./actions"

const { reducer } = createSlice({
  name: "email",
  initialState: null,

  extraReducers: {
    [authenticateSuccess]: (state, { payload: { email } }) => email,
    [bypassAuthentication]: () => "forcedUser",
  },
})

export default reducer
