import React from "react"
import { connect } from "react-redux"
import ConditioningSelect from "components/Select/ConditioningSelect"
import t from "i18n"
import { useUri } from "utils/router"
import { setBevProductData } from "ducks/bevProductData/actions"

const Conditioning = ({ bevQuotation, setBevProductData }) => {
  const { bevProductData = {} } = bevQuotation
  const { productInfo } = bevProductData
  const { currentQuotationId } = useUri()

  return (
    <ConditioningSelect
      value={productInfo?.conditioning || ""}
      label={t("packagingField")}
      fullWidth
      onChange={e =>
        setBevProductData({
          fieldName: "productInfo.conditioning",
          fieldValue: e.target.value,
          bevQuotation,
          currentQuotationId,
        })
      }
    />
  )
}

export default connect(null, {
  setBevProductData,
})(Conditioning)
