import { createSlice } from "@reduxjs/toolkit"
import { selectSearchedBevProductData } from "./actions"
import { selectSearchedBevProductDataHandler } from "../../utils/bevProductData"

const { reducer } = createSlice({
  name: "id",
  initialState: null,
  reducers: {},
  extraReducers: {
    [selectSearchedBevProductData]: selectSearchedBevProductDataHandler("id"),
  },
})

export default reducer
