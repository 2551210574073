import React from "react"
import { ZoneCell } from "../TableCell"
import ClampedTypo from "../../ClampedTypo"
import { getZoneText } from "utils/zone"

const Zone = ({ zone }) => (
  <ZoneCell>
    <ClampedTypo variant="body2" noWrap>
      {getZoneText(zone)}
    </ClampedTypo>
  </ZoneCell>
)

export default Zone
