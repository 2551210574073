import React, { useEffect } from "react"
import styled from "styled-components"
import { colors } from "../Theme/colors"
import { Typography, Container } from "@material-ui/core"
import t from "i18n"
import { connect } from "react-redux"
import { useUri } from "../../utils/router"
import { currentQuotation } from "../../ducks/currentQuotation"
import { createQuotationAction } from "../../ducks/currentQuotation/actions"

const LoadingContainer = styled.div`
  background-color: ${colors.primary.main};
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const WhiteTypography = styled(Typography)`
  color: ${colors.white};
`

const LoadingAnimation = styled.img`
  width: 70vw;
  max-width: 450px;
  margin-bottom: 15px;
`

const LoadingPage = ({ currentQuotation, createQuotation }) => {
  const { currentQuotationId } = useUri()

  useEffect(() => {
    createQuotation({
      currentQuotation: { ...currentQuotation, id: currentQuotationId },
    })
    //if currentQuotation is put in deps, it dispatch the action multiple times when failing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LoadingContainer>
      <ContentContainer maxWidth="md">
        <LoadingAnimation src="https://resources.matcha.wine/wine-select/waiting-screen-animation.gif" />

        <WhiteTypography align="center" variant="h6" paragraph>
          {t("waitingPageTitle")}
        </WhiteTypography>

        <WhiteTypography align="center" paragraph>
          {t("waitingPageSubtitle")}
        </WhiteTypography>
      </ContentContainer>
    </LoadingContainer>
  )
}

export default connect(
  state => ({
    currentQuotation: currentQuotation(state),
  }),
  { createQuotation: createQuotationAction },
)(LoadingPage)
