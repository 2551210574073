import { createSlice } from "@reduxjs/toolkit"
import { setBevProductData } from "../bevProductData/actions"
import {
  closeFieldListhandler,
  debounceGetFieldList,
  getFieldListErrorHandler,
  getFieldListHandler,
  getFieldListSuccess,
  openFieldListHandler,
} from "utils/fieldUtils"

const initialState = {
  isLoading: false,
  list: [],
  isOpen: false,
}

const {
  reducer,
  actions: {
    getZoneListError,
    getZoneListSuccess,
    closeZoneList,
    openZoneList,
    getZoneList: getZoneListAction,
  },
} = createSlice({
  name: "zone",
  initialState,
  reducers: {
    openZoneList: openFieldListHandler,
    getZoneList: getFieldListHandler,
    getZoneListError: getFieldListErrorHandler,
    getZoneListSuccess: getFieldListSuccess("zoneList"),
    closeZoneList: closeFieldListhandler,
  },
  extraReducers: {
    [setBevProductData]: () => initialState,
  },
})

export const getZoneList = debounceGetFieldList(getZoneListAction)

export {
  getZoneListSuccess,
  getZoneListError,
  closeZoneList,
  openZoneList,
  getZoneListAction,
}

export default reducer
