import React from "react"
import ColorSelect from "../../../Select/ColorSelect"
import t from "i18n"
import { connect } from "react-redux"
import { useUri } from "utils/router"
import { setBevProductData } from "ducks/bevProductData/actions"

const Color = ({ bevQuotation, setBevProductData }) => {
  const { currentQuotationId } = useUri()
  const { bevProductData: { color } = {} } = bevQuotation

  return (
    <ColorSelect
      label={t("colorField")}
      fullWidth
      color={color}
      required
      onChange={e =>
        setBevProductData({
          fieldName: "color",
          fieldValue: e.target.value,
          bevQuotation,
          currentQuotationId,
        })
      }
    />
  )
}

export default connect(null, { setBevProductData })(Color)
