import _ from "lodash"

const lang = "en"

const textMap = require("./textMap.json")
const getTextData = id => _.get(textMap, `${id}.text`)

const t = (id, templateData) => {
  const textData = getTextData(id)
  if (!textData) throw new Error(`Unknown i18n id ${id}`)
  const langText = textData[lang] || id
  const cleanedText = langText.replace(/\\n/g, "\n")

  return templateData ? _.template(cleanedText)(templateData) : cleanedText
}

export default t
