import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import {
  getQuotationSuccess,
  onCloseQuotationResultPage,
  setCurrentQuotation,
} from "./actions"

const initialState = ""
const { reducer, actions } = createSlice({
  name: "name",
  initialState,
  reducers: {
    setQuotationName: (state, { payload }) => payload,
  },
  extraReducers: {
    [setCurrentQuotation]: _.identity,
    [getQuotationSuccess]: (
      state,
      {
        payload: {
          quotation: { name },
        },
      },
    ) => name,
    [onCloseQuotationResultPage]: () => initialState,
  },
})

export const { setQuotationName } = actions

export default reducer
