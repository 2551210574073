import React from "react"
import { StylesProvider, MuiThemeProvider } from "@material-ui/core"
import { theme } from "../Theme/theme"

const InitMaterialUI = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </StylesProvider>
)

export default InitMaterialUI
