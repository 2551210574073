import React from "react"
import { Button } from "@material-ui/core"
import styled from "styled-components"
import { Link } from "react-router-dom"
import Icon from "../Icon"

const NoHoverButton = styled(Button)`
  &:hover {
    background-color: unset;
  }
`
const HeaderButton = ({ uri, iconName = "back", size = 25 }) => (
  <NoHoverButton component={Link} to={uri}>
    <Icon name={iconName} size={size} />
  </NoHoverButton>
)

export default HeaderButton
