import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { connect } from "react-redux"
import { bevQuotationStatus } from "ducks/bevQuotation"
import styled from "styled-components"
import { colors } from "components/Theme/colors"
import { statusList } from "utils/status"
import _ from "lodash"

const WineQuotationStatusDisplay = styled.div`
  width: 100%;
  padding: 7px;
  border-radius: 100px;
  background-color: ${({ status }) => status && colors[status]};
  color: ${colors.white};
  margin: 0 10px;
`

const WineQuotationStatus = ({ wineQuotationStatus }) => {
  const { name: statusName } =
    _.find(statusList, { id: wineQuotationStatus }) || {}

  return (
    <Grid container justify="center">
      <WineQuotationStatusDisplay status={wineQuotationStatus}>
        <Typography color="inherit" align="center">
          {statusName}
        </Typography>
      </WineQuotationStatusDisplay>
    </Grid>
  )
}

export default connect((state, props) => ({
  wineQuotationStatus: bevQuotationStatus(
    state.currentQuotation.bevQuotations.byId,
    props,
  ),
}))(WineQuotationStatus)
