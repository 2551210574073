import React from "react"
import { connect } from "react-redux"
import { useUri } from "utils/router"
import { modifyBevQuotationVolume } from "ducks/bevProductData/productInfo/volume"
import FormatSelect from "components/Select/FormatSelect"
import { FormatCell } from "../TableCell"

export const Format = ({ bevQuotation, modifyBevQuotationVolume }) => {
  const {
    id,
    bevProductData: { productInfo: { volume } = {} } = {},
  } = bevQuotation
  const { currentQuotationId } = useUri()

  return (
    <FormatCell>
      <FormatSelect
        value={volume}
        data-testid={`formatSelect-${id}`}
        onChange={({ target: { value: volume } }) =>
          modifyBevQuotationVolume({ currentQuotationId, volume, bevQuotation })
        }
        itemTestId="formatItem"
        preventDefault
      />
    </FormatCell>
  )
}

export default connect(null, { modifyBevQuotationVolume })(Format)
