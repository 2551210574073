import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link, Route, Switch } from "react-router-dom"
import { Button, Container } from "@material-ui/core"
import { getUri, Path, useUri } from "utils/router"
import WineQuotationModal from "./WineQuotationModal"
import ExportModal from "./ExportModal"
import styled from "styled-components"
import Page from "../Page"
import BevQuotationsTables from "./BevQuotationsTable"
import { getQuotationAction } from "ducks/currentQuotation/actions"
import Filters from "./Filters"
import { Status } from "ducks/currentQuotation/bevQuotations"
import HeaderButton from "../HeaderButton"
import t from "i18n"
import { onCloseQuotationResultPage } from "ducks/currentQuotation/actions"

const ResultContainer = styled(Container)`
  flex: 1 1 auto;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 50px;
`

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`

const ResultPage = ({
  getQuotation,
  quotationName,
  onCloseQuotationResultPage,
}) => {
  const uriState = useUri()
  const { status = Status.all, currentQuotationId } = uriState

  useEffect(() => {
    getQuotation({ currentQuotationId })
  }, [getQuotation, currentQuotationId])

  useEffect(() => () => onCloseQuotationResultPage(), [
    onCloseQuotationResultPage,
  ])

  return (
    <Page
      headerProps={{
        title: quotationName,
        leftComponent: <HeaderButton uri={getUri({ path: Path.quotation })} />,
        rightComponent: (
          <FlexContainer>
            <Filters />
            <Button
              variant="outlined"
              component={Link}
              to={getUri({
                path: Path.quotationExport,
                ...uriState,
              })}
            >
              {t("exportButton")}
            </Button>
          </FlexContainer>
        ),
      }}
    >
      <ResultContainer maxWidth="xl">
        <BevQuotationsTables status={status} />

        <Switch>
          <Route path={Path.quotationWine} component={WineQuotationModal} />
          <Route path={Path.quotationExport} component={ExportModal} />
        </Switch>
      </ResultContainer>
    </Page>
  )
}

export default connect(
  state => ({
    quotationName: state.currentQuotation.name,
  }),
  { getQuotation: getQuotationAction, onCloseQuotationResultPage },
)(ResultPage)
