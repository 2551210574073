import React from "react"
import { Modal as MuiModal } from "@material-ui/core"
import styled from "styled-components"

const CenteredModal = styled(MuiModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Modal = props => <CenteredModal {...props} />

export default Modal
