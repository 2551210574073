import React from "react"
import TableHeader from "../TableHeader"
import QuotationWineLine from "../QuotationWineLine"
import styled from "styled-components"
import { connect } from "react-redux"
import { bevQuotationsByStatus } from "ducks/currentQuotation/bevQuotations"
import { FixedSizeList as List } from "react-window"
import Autosizer from "react-virtualized-auto-sizer"

const TableContainer = styled.div`
  min-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
`

//negative margin for the scroll bar
const TableContentContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  margin-right: -32px;
`

const LineWrapper = ({ index, style, data }) => (
  <div key={index} style={style}>
    <QuotationWineLine bevQuotation={data[index]} wineIndex={index} />
  </div>
)

const BevQuotationsTables = ({ bevQuotations = [] }) => {
  const itemCount = bevQuotations.length

  return (
    <TableContainer data-testid="quotationTable">
      <TableHeader />
      <TableContentContainer>
        <Autosizer>
          {({ width, height }) => (
            <List
              height={height}
              width={width}
              itemCount={itemCount}
              itemSize={95}
              overscanCount={1}
              itemData={bevQuotations}
            >
              {LineWrapper}
            </List>
          )}
        </Autosizer>
      </TableContentContainer>
    </TableContainer>
  )
}

export default connect((state, props) => ({
  bevQuotations: bevQuotationsByStatus(props.status)(state, props),
}))(BevQuotationsTables)
