import history from "./history"

const key = "redirectUrl"

export const saveRedirectUrl = () => {
  const oldRedirectUrl = sessionStorage.getItem(key)
  if (!oldRedirectUrl) sessionStorage.setItem(key, window.location.pathname)
}

const getRedirectUrl = () => {
  const redirectUrl = sessionStorage.getItem(key)
  sessionStorage.removeItem(key)
  return redirectUrl
}

export const redirectToOldUrl = () => {
  const redirectUrl = getRedirectUrl()
  console.log("redirect to url", redirectUrl)
  if (redirectUrl && redirectUrl !== "/") history.push(redirectUrl)
}
