import React from "react"
import { Redirect } from "react-router-dom"
import { getUri } from "../utils/router"
import uuidV4 from "uuid/v4"
import { Path } from "../utils/router"

const CreateQuotationRedirect = () => {
  const currentQuotationId = uuidV4()
  const uri = getUri({ path: Path.newQuotationWithId, currentQuotationId })

  return <Redirect to={uri} />
}

export default CreateQuotationRedirect
