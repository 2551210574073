import { combineReducers } from "redux"
import color from "./color"
import id from "./id"
import pricings from "./pricings"
import producer from "./producer"
import productInfo from "./productInfo"
import zone from "./zone"
import cuvee from "./cuvee"
import vintage from "./vintage"
import numOffers from "./numOffers"
import marketPrice from "./marketPrice"
import { selectSearchedBevProductData } from "./actions"

export { selectSearchedBevProductData }

export default combineReducers({
  color,
  id,
  pricings,
  producer,
  productInfo,
  zone,
  cuvee,
  vintage,
  numOffers,
  marketPrice,
})
