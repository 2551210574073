import React from "react"
import App from "./App"
import AppInitiator from "./InitComponents"

const Root = () => (
  <AppInitiator>
    <App />
  </AppInitiator>
)

export default Root
