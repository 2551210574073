import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import QuotationWineLineSection from "./QuotationWineLineSection"
import ProducerAndCuvee from "./ProducerAndCuvee"
import Color from "./Color"
import Format from "./Format"
import Conditioning from "./Conditioning"
import OriginalSearch from "./OriginalSearch"
import Vintage from "./Vintage"
import Zone from "./Zone"
import Action from "./Action"
import { bevQuotationStatus } from "ducks/bevQuotation"
import { Link } from "react-router-dom"
import { getUri, useUri, Path } from "utils/router"
import Price from "../Price"
import WineIndex from "./WineIndex"

const WineLineContainer = styled(Grid)`
  margin: 20px 0 5px 0;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: unset;
`
const QuotationWineLine = ({ bevQuotation, status, wineIndex }) => {
  const { rawInput, bevProductData, id } = bevQuotation
  const { cuvee, producer, zone, color } = bevProductData
  const uriState = useUri()

  return (
    <StyledLink
      to={getUri({
        path: Path.quotationWine,
        bevQuotationId: id,
        ...uriState,
      })}
    >
      <WineLineContainer
        container
        justify="flex-start"
        wrap="nowrap"
        data-testid="quotationTableWineLine"
      >
        <QuotationWineLineSection xs={3} status={status} rounded withShadow>
          <WineIndex wineIndex={wineIndex + 1} />
          <OriginalSearch status={status} rawInput={rawInput} />
        </QuotationWineLineSection>

        <QuotationWineLineSection xs={10} status={status} rounded withShadow>
          <ProducerAndCuvee producer={producer} cuvee={cuvee} />

          <Zone zone={zone} />

          <Color color={color} />

          <Vintage bevQuotation={bevQuotation} />

          <Format bevQuotation={bevQuotation} />

          <Conditioning bevQuotation={bevQuotation} />
        </QuotationWineLineSection>

        <QuotationWineLineSection xs={1} status={status} rounded withShadow>
          <Price bevProductData={bevProductData} />
        </QuotationWineLineSection>

        <Action status={status} bevQuotation={bevQuotation} />
      </WineLineContainer>
    </StyledLink>
  )
}

export default connect((state, props) => ({
  status: bevQuotationStatus(state.currentQuotation.bevQuotations.byId, props),
}))(QuotationWineLine)
