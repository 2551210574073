import React from "react"
import { connect } from "react-redux"
import { Card, Typography } from "@material-ui/core"
import t from "../../../i18n"
import styled from "styled-components"
import {
  bevQuotationsByStatus,
  bevQuotationsLength,
  Status,
} from "ducks/currentQuotation/bevQuotations"
import { colors } from "../../Theme/colors"
import { Path, getUri, useUri } from "utils/router"
import { Link } from "react-router-dom"
import _ from "lodash"

const FlexCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 15px 25px 30px 25px;
  margin-top: 30px;
`

const SummaryTypo = styled(Typography)`
  text-decoration: underline;
  font-weight: 600;
`

const Bullet = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${colors.text.secondary};
  border-radius: 50%;
  margin-right: 15px;
`
const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`

const BevQuotationsLink = ({ linkText, status }) => {
  const { currentQuotationId } = useUri()

  return (
    <LinkContainer>
      <Bullet />
      <Link
        to={getUri({
          path: Path.quotationResult,
          currentQuotationId,
          status,
        })}
      >
        <SummaryTypo color="primary">{linkText}</SummaryTypo>
      </Link>
    </LinkContainer>
  )
}

const BevQuotationsSummary = ({
  matchedBevQuotations,
  notMatchedBevQuotations,
  abandonedBevQuotations,
  bevQuotationsLength,
}) => {
  return (
    <FlexCard>
      <Typography variant="subtitle1">{t("exportFirstPart")}</Typography>

      <BevQuotationsLink
        linkText={t("importedWinesLink", { bevQuotationsLength })}
      />

      {!_.isEmpty(matchedBevQuotations) && (
        <BevQuotationsLink
          linkText={t("foundWineslink", {
            matchedBevQuotationsLength: matchedBevQuotations.length,
          })}
          status="matched"
        />
      )}

      {!_.isEmpty(notMatchedBevQuotations) && (
        <BevQuotationsLink
          linkText={t("winesToCorrectLink", {
            notMatchedBevQuotationsLength: notMatchedBevQuotations.length,
          })}
          status="notMatched"
        />
      )}

      {!_.isEmpty(abandonedBevQuotations) && (
        <BevQuotationsLink
          linkText={t("abandonedWinesLink", {
            abandonedBevQuotationsLength: abandonedBevQuotations.length,
          })}
          status="abandoned"
        />
      )}
    </FlexCard>
  )
}

export default connect((state, props) => ({
  matchedBevQuotations: bevQuotationsByStatus(Status.matched)(state, props),
  notMatchedBevQuotations: bevQuotationsByStatus(Status.notMatched)(
    state,
    props,
  ),
  abandonedBevQuotations: bevQuotationsByStatus(Status.abandoned)(state, props),
  bevQuotationsLength: bevQuotationsLength(state),
}))(BevQuotationsSummary)
