import React from "react"
import { Grid, TextField, Typography } from "@material-ui/core"
import styled from "styled-components"
import t from "i18n"

const FlexAutoItem = styled(Grid)`
  flex: 1 1 auto;
`
const OriginalSearch = ({ rawInput = "" }) => (
  <Grid container alignItems="flex-end" spacing={3}>
    <Grid item>
      <Typography variant="subtitle2">{t("originalSearchField")}</Typography>
    </Grid>
    <FlexAutoItem item>
      <TextField fullWidth value={rawInput} disabled />
    </FlexAutoItem>
  </Grid>
)

export default OriginalSearch
