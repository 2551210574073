import styled from "styled-components"
import { Grid } from "@material-ui/core"
import React from "react"
import QuotationWineLineSection from "../QuotationWineLine/QuotationWineLineSection"

const TableCell = styled(Grid)`
  display: flex;
  align-items: center;
  padding: 20px;
  height: 70px;
`

const ActionContainer = styled(QuotationWineLineSection)`
  min-width: 70px;
  flex-grow: 0;
`

const StyledOriginalSearchCell = styled(TableCell)`
  padding: 0;
`
export const ProducerAndCuveeCell = props => (
  <TableCell item xs={5} xl={5} zeroMinWidth {...props} />
)

export const ColorCell = props => (
  <TableCell item xs={1} xl={1} zeroMinWidth {...props} />
)

export const FormatCell = props => <TableCell item xs={1} xl={1} {...props} />

export const ConditioningCell = props => (
  <TableCell item xs={1} xl={1} {...props} />
)
export const OriginalSearchCell = props => (
  <StyledOriginalSearchCell item xs={12} zeroMinWidth {...props} />
)

export const VintageCell = props => (
  <TableCell item xs={1} xl={1} zeroMinWidth {...props} />
)

export const ZoneCell = props => (
  <TableCell item xs={3} zeroMinWidth {...props} />
)

export const PriceCell = TableCell
export const ActionCell = ({ status, children, withShadow }) => (
  <ActionContainer status={status} rounded withShadow={withShadow}>
    <Grid item>{children}</Grid>
  </ActionContainer>
)

const StyledWineIndexCell = styled(TableCell)`
  padding: 0 10px;
  font-weight: bold;
`

export const WineIndexCell = props => (
  <StyledWineIndexCell {...props} item xs={2} />
)

export default TableCell
