import React from "react"
import Modal from "../../Modal"
import { Path, redirectToUri, useUri } from "utils/router"
import { Button, Card, Container, Typography } from "@material-ui/core"
import { colors } from "../../Theme/colors"
import styled from "styled-components"
import t from "i18n"
import { connect } from "react-redux"
import { exportQuotationAsCsv } from "utils/exporter"
import Icon from "../../Icon"
import BevQuotationsSummary from "./BevQuotationsSummary"
import { allBevQuotations } from "ducks/currentQuotation/bevQuotations"

const ContentContainer = styled(Container)`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 30px 70px;
  &:focus {
    outline: none;
  }
`

const FlexCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  margin-top: 30px;
`

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 30px;
`

const MarginIcon = styled(Icon)`
  margin-right: 10px;
`
const ExportModal = ({ quotationName, bevQuotations }) => {
  const uriState = useUri()

  return (
    <Modal
      open
      onClose={() =>
        redirectToUri({
          path: Path.quotationResult,
          ...uriState,
        })
      }
    >
      <ContentContainer maxWidth="sm">
        <Typography variant="h6">{t("exportTitle")}</Typography>

        <BevQuotationsSummary />

        <FlexCard>
          <Typography variant="subtitle1">{t("exportSecondPart")}</Typography>
          <ButtonsContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                exportQuotationAsCsv({ quotationName, bevQuotations })
              }
            >
              <MarginIcon name="csv-file" color="inherit" size={22} />
              {t("csvButton")}
            </Button>
          </ButtonsContainer>
        </FlexCard>
      </ContentContainer>
    </Modal>
  )
}

export default connect(state => ({
  bevQuotations: allBevQuotations(state),
  quotationName: state.currentQuotation.name,
}))(ExportModal)
