import React from "react"
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core"
import { useUID } from "react-uid"
import _ from "lodash"

const GenericSelect = props => {
  const {
    value,
    items,
    displayItem = _.identity,
    preventDefault,
    itemTestId,
    onChange,
    fullWidth,
    label,
    required,
  } = props
  const dataTestId = props["data-testid"]
  const labelId = useUID()

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel id={labelId} required={required}>
          {label}
        </InputLabel>
      )}
      <Select
        value={value || ""}
        onClick={e => preventDefault && e.preventDefault()}
        data-testid={dataTestId}
        onChange={onChange}
        labelId={labelId}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            value={item}
            data-testid={`${itemTestId}-${item}`}
          >
            {displayItem(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default GenericSelect
