import React from "react"
import { Autocomplete } from "@material-ui/lab"
import { TextField } from "@material-ui/core"
import _ from "lodash"

const defaultGetOptionLabel = option =>
  option?.selectorName || option?.name || ""

const defaultGetOptionSelected = (option, value) => option?.name === value?.name

const GenericAutoComplete = ({
  label,
  field,
  fieldValue,
  onChange,
  onBlur,
  onFocus,
  onOpen,
  fullWidth,
  onClose,
  getOptionLabel = defaultGetOptionLabel,
  getOptionSelected = defaultGetOptionSelected,
  onInputChange,
  open,
  required,
}) => {
  const { isLoading, list } = field

  return (
    <Autocomplete
      open={open}
      forcePopupIcon={true}
      disableOpenOnFocus
      autoComplete={true}
      autoSelect={true}
      onChange={onChange}
      onInputChange={onInputChange}
      onBlur={onBlur}
      onOpen={onOpen}
      onFocus={onFocus}
      onClose={onClose}
      options={list}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={_.identity}
      loading={isLoading}
      value={fieldValue || null}
      renderInput={props => {
        return (
          <TextField
            {...props}
            label={label}
            fullWidth={fullWidth}
            required={required}
          />
        )
      }}
    />
  )
}

export default GenericAutoComplete
