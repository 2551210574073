import React from "react"
import { Typography, Grid, Paper } from "@material-ui/core"
import styled from "styled-components"
import { getProducerAndCuvee } from "utils/bev"
import Price from "../Price"
import Icon from "components/Icon"
import { colors } from "components/Theme/colors"
import { bevQuotationFromId } from "ducks/bevQuotation"
import { connect } from "react-redux"
import t from "i18n"
import { selectSearchedBevProductData } from "ducks/bevProductData"
import { useUri } from "utils/router"
import ClampedTypo from "../../ClampedTypo"
import { getZoneText } from "utils/zone"
import { getColorNameFromId } from "utils/colors"
import _ from "lodash"

const ListContainer = styled.div`
  flex: 1 0 auto;
  margin-top: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  //width: calc(100% + 30px / 2);
  width: 100%;
`

const BevLineSectionContainer = styled(Paper)`
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
`

const FixedWithGrid = styled(Grid)`
  width: 70px;
  height: 70px;
`

const FillGrid = styled(Grid)`
  flex: 1 1 auto;
`

const GridCell = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  margin-right: 10px;
  padding-left: 15px;
`

const NoSearchedBevTypography = styled(Typography)`
  margin-top: 100px;
  white-space: pre-line;
`
const ProducerAndCuvee = ({ producer, cuvee }) => (
  <GridCell item xs={6}>
    <ClampedTypo variant="subtitle2">
      {getProducerAndCuvee({ producer, cuvee })}
    </ClampedTypo>
  </GridCell>
)

const Zone = ({ zone }) => (
  <GridCell item xs={3}>
    <ClampedTypo variant="subtitle2">{getZoneText(zone)}</ClampedTypo>
  </GridCell>
)

const Color = ({ color }) => (
  <GridCell item xs={1}>
    <Grid container alignItems="center" justify="center">
      <Typography variant="subtitle2" align="center">
        {getColorNameFromId(color)}
      </Typography>
    </Grid>
  </GridCell>
)

const Vintage = ({ vintage }) => (
  <GridCell item xs={1}>
    <Typography variant="subtitle2" align="center">
      {vintage}
    </Typography>
  </GridCell>
)

const Volume = ({ productInfo = {} }) => (
  <GridCell item xs={1}>
    <Typography variant="subtitle2" align="center">
      {t("volumeText", productInfo)}
    </Typography>
  </GridCell>
)

const PriceCell = ({ bevProductData }) => (
  <FillGrid item>
    <BevLineSectionContainer>
      <Price bevProductData={bevProductData} />
    </BevLineSectionContainer>
  </FillGrid>
)
const QuotationCorrectionBevLine = ({ bevProductData, onClick }) => {
  const {
    producer = {},
    cuvee = {},
    zone = {},
    color,
    vintage,
    productInfo,
    id,
  } = bevProductData
  return (
    <Grid container wrap="nowrap">
      <Grid item xs={10}>
        <BevLineSectionContainer>
          <Grid container wrap="nowrap">
            <ProducerAndCuvee producer={producer} cuvee={cuvee} />

            <Zone zone={zone} />

            <Color color={color} />

            <Vintage vintage={vintage} />

            <Volume productInfo={productInfo} />
          </Grid>
        </BevLineSectionContainer>
      </Grid>

      <PriceCell bevProductData={bevProductData} />

      <FixedWithGrid item>
        <BevLineSectionContainer
          data-testid={`selectBevProductData-${id}`}
          onClick={onClick}
        >
          <Icon color={colors.primary.main} name="refresh" />
        </BevLineSectionContainer>
      </FixedWithGrid>
    </Grid>
  )
}

const ErrorSearchedBev = ({ errorMessage }) => (
  <NoSearchedBevTypography align="center" color="primary">
    {errorMessage}
  </NoSearchedBevTypography>
)

const SearchedBevProductData = ({
  bevQuotation,
  selectSearchedBevProductData,
}) => {
  const { currentQuotationId } = useUri()
  const { searchedBevProductDatas = [] } = bevQuotation

  return searchedBevProductDatas.map(bevProductData => (
    <QuotationCorrectionBevLine
      bevProductData={bevProductData}
      onClick={() =>
        selectSearchedBevProductData({
          bevQuotation,
          bevProductData,
          currentQuotationId,
        })
      }
      key={bevProductData.id}
    />
  ))
}

const QuotationBevListError = ({ hasTooMuchSearchedBev }) =>
  hasTooMuchSearchedBev ? (
    <ErrorSearchedBev errorMessage={t("tooMuchSearchedBevProductData")} />
  ) : (
    <ErrorSearchedBev errorMessage={t("noSearchedBevProductData")} />
  )

const QuotationBevList = ({
  bevQuotation = {},
  selectSearchedBevProductData,
}) => {
  const {
    hasTooMuchSearchedBev = null,
    searchedBevProductDatas = [],
  } = bevQuotation

  return (
    <ListContainer>
      {_.isEmpty(searchedBevProductDatas) &&
      !_.isNull(hasTooMuchSearchedBev) ? (
        <QuotationBevListError hasTooMuchSearchedBev={hasTooMuchSearchedBev} />
      ) : (
        <SearchedBevProductData
          selectSearchedBevProductData={selectSearchedBevProductData}
          bevQuotation={bevQuotation}
        />
      )}
    </ListContainer>
  )
}

export default connect(bevQuotationFromId, { selectSearchedBevProductData })(
  QuotationBevList,
)
