import React from "react"
import { Typography, TextField, Fab, Container } from "@material-ui/core"
import t from "i18n"
import styled from "styled-components"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { setQuotationName } from "ducks/currentQuotation/name"
import { setQuotationRawInput } from "ducks/currentQuotation/rawInput"
import { getUri, useUri, Path } from "utils/router"
import Page from "../Page"
import { currentQuotation, isValidQuotationInput } from "ducks/currentQuotation"
import HeaderButton from "../HeaderButton"

const MarginDiv = styled.div`
  margin: 30px 0;
  width: 80%;
`

const StyledFab = styled(Fab)`
  min-width: 150px;
`

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
`

const PageDescription = () => (
  <MarginDiv>
    <Typography align="center">{t("importPageDescription")}</Typography>
  </MarginDiv>
)

const QuotationRawInput = ({ quotationRawInput, setQuotationRawInput }) => (
  <MarginDiv>
    <TextField
      multiline
      variant="outlined"
      data-testid="importPageTextarea"
      fullWidth
      rows={20}
      placeholder={t("importPageFrame")}
      value={quotationRawInput}
      onChange={e => setQuotationRawInput(e.target.value)}
    />
  </MarginDiv>
)

const QuotationInput = ({ quotationName, setQuotationName }) => (
  <MarginDiv>
    <TextField
      fullWidth
      variant="outlined"
      data-testid="importPageInput"
      placeholder={t("importPageInputPlaceholder")}
      value={quotationName}
      onChange={e => setQuotationName(e.target.value)}
    />
  </MarginDiv>
)

const SubmitNewQuotation = ({ isValidQuotationInput }) => {
  const { currentQuotationId } = useUri()

  return (
    <div>
      <StyledFab
        color="secondary"
        variant="extended"
        data-testid="importPageImportButton"
        disabled={isValidQuotationInput}
        component={Link}
        to={getUri({ path: Path.loadingQuotation, currentQuotationId })}
      >
        <Typography>{t("importPageCTA")}</Typography>
      </StyledFab>
    </div>
  )
}

export const CreateQuotationPage = ({
  currentQuotation = {},
  setQuotationName,
  setQuotationRawInput,
  isValidQuotationInput,
}) => {
  return (
    <Page
      headerProps={{
        title: t("importPageTitle"),
        leftComponent: <HeaderButton uri={getUri({ path: Path.quotation })} />,
      }}
    >
      <FlexContainer maxWidth="md">
        <PageDescription />

        <QuotationInput
          quotationName={currentQuotation.name}
          setQuotationName={setQuotationName}
        />

        <QuotationRawInput
          quotationRawInput={currentQuotation.rawInput}
          setQuotationRawInput={setQuotationRawInput}
        />

        <SubmitNewQuotation isValidQuotationInput={isValidQuotationInput} />
      </FlexContainer>
    </Page>
  )
}

export default connect(
  state => ({
    currentQuotation: currentQuotation(state),
    isValidQuotationInput: isValidQuotationInput(state),
  }),
  {
    setQuotationName,
    setQuotationRawInput,
  },
)(CreateQuotationPage)
