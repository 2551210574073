import { createSlice } from "@reduxjs/toolkit"
import {
  getQuotationSuccess,
  createQuotationSuccess,
} from "../currentQuotation/actions"

const handleReceivedQuotation = () => null

const { reducer } = createSlice({
  name: "hasTooMuchSearchedBev",
  initialState: false,
  reducers: {},
  extraReducers: {
    [createQuotationSuccess]: handleReceivedQuotation,
    [getQuotationSuccess]: handleReceivedQuotation,
  },
})

export default reducer
