import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

const {
  reducer,
  actions: { abandonBevQuotation, reenableBevQuotation },
} = createSlice({
  name: "userStatus",
  initialState: null,
  reducers: {
    reenableBevQuotation: {
      reducer: _.identity,
      prepare: ({ bevQuotation, currentQuotationId }) => ({
        payload: {
          currentQuotationId,
          bevQuotation: {
            ...bevQuotation,
            userStatus: null,
          },
        },
      }),
    },
    abandonBevQuotation: {
      reducer: _.identity,
      prepare: ({ bevQuotation, currentQuotationId }) => ({
        payload: {
          currentQuotationId,
          bevQuotation: {
            ...bevQuotation,
            userStatus: "abandoned",
          },
        },
      }),
    },
  },
})

export { abandonBevQuotation, reenableBevQuotation }

export default reducer
