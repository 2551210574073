import React from "react"
import { connect } from "react-redux"
import t from "i18n"
import {
  closeDomainList,
  openDomainList,
  getDomainList,
} from "ducks/fields/domain"
import FieldAutoComplete from "./FieldAutoComplete"

const Domain = ({
  domainField,
  closeDomainList,
  bevQuotation,
  openDomainList,
  getDomainList,
}) => {
  const { bevProductData = {} } = bevQuotation
  const { producer, color: colorId, zone } = bevProductData
  const zoneId = zone?.id
  const { isLoading } = domainField
  const shouldFetchList = !isLoading

  return (
    <FieldAutoComplete
      fullWidth
      required
      field={domainField}
      fieldValue={producer}
      fieldName="producer"
      label={t("domaineField")}
      getFieldList={({ producer }) =>
        getDomainList({ colorId, zoneId, producer })
      }
      closeFieldList={closeDomainList}
      bevQuotation={bevQuotation}
      openFieldList={openDomainList}
      shouldFetchList={shouldFetchList}
    />
  )
}

export default connect(state => ({ domainField: state.fields.domain }), {
  closeDomainList,
  getDomainList,
  openDomainList,
})(Domain)
