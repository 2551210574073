import React, { useEffect } from "react"
import { connect } from "react-redux"
import Page from "../Page"
import t from "i18n"
import styled from "styled-components"
import { Container, Typography } from "@material-ui/core"
import QuotationCard from "./QuotationCard"
import CreateQuotation from "./CreateQuotation"
import { getQuotationsList } from "ducks/quotations"

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  flex: 1 1 auto;
  overflow: hidden;
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0;
  flex: 1 1 auto;
  overflow-y: auto;
`

const MarginTypo = styled(Typography)`
  margin-left: 56px;
`

const QuotationsList = ({ getQuotationsList, quotationsList = [] }) => {
  useEffect(() => {
    getQuotationsList()
  }, [getQuotationsList])

  return (
    <Page headerProps={{ title: t("listingHeader") }}>
      <FlexContainer maxWidth="lg">
        <MarginTypo variant="h6">{t("listingTitle")}</MarginTypo>
        <ListContainer>
          {quotationsList.map(quotation => (
            <QuotationCard quotation={quotation} key={quotation.id} />
          ))}
        </ListContainer>
        <CreateQuotation />
      </FlexContainer>
    </Page>
  )
}

export default connect(
  state => ({
    quotationsList: state.quotations,
  }),
  { getQuotationsList },
)(QuotationsList)
