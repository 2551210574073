import _ from "lodash"
import t from "i18n"

export const getFixedFloat = price => {
  const priceFloat = parseFloat(price)
  const priceText = priceFloat ? priceFloat.toString() : ""
  if (_.includes(priceText, ".")) return priceFloat.toFixed(2).replace(".", ",")
  return isNaN(priceFloat) ? priceText : priceFloat
}

export const pricingsSources = [
  { id: "FARR_VINTNERS", name: "Farr Vintners" },
  { id: "BORDEAUX_INDEX", name: "Bordeaux Index" },
  { id: "SECKFORD", name: "Seckford" },
  { id: "LUCULLUS", name: "Lucullus" },
]

export const pricingsColumnsHeaders = _.flatMap(pricingsSources, ({ name }) => [
  t("offerPriceColumn", { name }),
  t("offerDateColumn", { name }),
])

export const getCsvPricings = pricings =>
  _.flatMap(pricingsSources, ({ id }) => {
    const { price = "-", date = "-" } =
      _.find(pricings, ["source.id", id]) || {}

    return [`${getPriceText(price) || "-"}`, `${date}`]
  })

export const getPriceText = price => getFixedFloat(price)
