import { combineReducers } from "redux"
import zone from "./zone"
import domain from "./domain"
import cuvee from "./cuvee"

export default combineReducers({
  zone,
  domain,
  cuvee,
})
