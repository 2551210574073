import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import {
  setCurrentQuotation,
  getQuotationSuccess,
  onCloseQuotationResultPage,
} from "./actions"

const initialState = ""

const { reducer, actions } = createSlice({
  name: "rawInput",
  initialState,
  reducers: {
    setQuotationRawInput: (state, { payload }) => payload,
  },
  extraReducers: {
    [setCurrentQuotation]: _.identity,
    [getQuotationSuccess]: (
      state,
      {
        payload: {
          quotation: { rawInput },
        },
      },
    ) => rawInput,
    [onCloseQuotationResultPage]: () => initialState,
  },
})

export const { setQuotationRawInput } = actions

export default reducer
