import React from "react"
import { ColorCell } from "../TableCell"
import { Typography } from "@material-ui/core"
import styled from "styled-components"

const colors = {
  B: "White",
  R: "Red",
  Z: "Rosé",
  O: "Orange",
}

const FullWidthTypo = styled(Typography)`
  width: 100%;
`
const Color = ({ color }) => (
  <ColorCell>
    <FullWidthTypo variant="body2" noWrap>
      {color && colors[color]}
    </FullWidthTypo>
  </ColorCell>
)

export default Color
