import React from "react"
import { connect } from "react-redux"
import FormatSelect from "components/Select/FormatSelect"
import t from "i18n"
import { useUri } from "utils/router"
import { setBevProductData } from "ducks/bevProductData/actions"

const Format = ({ setBevProductData, bevQuotation }) => {
  const { bevProductData = {} } = bevQuotation
  const { productInfo } = bevProductData
  const { currentQuotationId } = useUri()

  return (
    <FormatSelect
      value={productInfo?.volume || ""}
      label={t("formatField")}
      fullWidth
      onChange={e =>
        setBevProductData({
          fieldName: "productInfo.volume",
          fieldValue: e.target.value,
          bevQuotation,
          currentQuotationId,
        })
      }
    />
  )
}

export default connect(null, { setBevProductData })(Format)
