import createAuth0Client from "@auth0/auth0-spa-js"

let auth0 = null

const getAuthConfig = () => ({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
})

const configureClient = async () => {
  const config = getAuthConfig()
  auth0 = await createAuth0Client(config)
}

const login = async () => {
  await auth0.loginWithRedirect({
    redirect_uri: window.location.origin,
  })
}

// Use when not possible with loginWithRedirect
// const loginWithPopup = async () => {
//   await auth0.auth0.loginWithPopup({
//     redirect_uri: window.location.origin,
//   })
// }

const logout = () => {
  auth0.logout({
    returnTo: window.location.origin,
  })
}

const authenticate = async () => {
  await configureClient()

  try {
    await auth0.handleRedirectCallback()
  } catch (e) {
    console.warn(e)
    await login()
  }

  const isAuthenticated = await auth0.isAuthenticated()

  if (!isAuthenticated) throw new Error("not authenticated")
}

const getUser = async () => await auth0.getUser()

const getUserMetadata = user => {
  const metadata = user["https://app-metadata.com"]
  if (!metadata) throw new Error("user metadata not found")
  return metadata
}

export const getAuthenticatedUser = async () => {
  await authenticate()
  const user = await getUser()
  console.debug("user from auth", user)
  const { matchaFinder: { clientId } = {} } = getUserMetadata(user)
  if (!clientId) throw new Error("Could not find client id metadata in user")

  return { ...user, clientId }
}

window.authenticate = authenticate
window.getUser = getUser
window.getUserMetadata = getUserMetadata
window.login = login
window.logout = logout
window.getAuthenticatedUser = getAuthenticatedUser
