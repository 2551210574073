import React from "react"
import { colors } from "utils/colors"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import t from "../../i18n"
import { useUID } from "react-uid"
import styled from "styled-components"

const ItalicTypography = styled(Typography)`
  font-style: ${({ italic }) => italic === "true" && "italic"};
`
const ColorSelect = ({ fullWidth, color, onChange, required }) => {
  const labelId = useUID()
  const colorList = required ? colors : [null, ...colors]

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={labelId} required={required}>
        {t("colorField")}
      </InputLabel>
      <Select
        value={color || ""}
        data-testid="colorSelect"
        onChange={onChange}
        labelId={labelId}
      >
        {colorList.map((color, index) => (
          <MenuItem
            key={index}
            value={color?.id || color}
            data-testid={`colorSelectItem-${color?.id}`}
          >
            <ItalicTypography italic={(!color).toString()}>
              {color?.name || "None"}
            </ItalicTypography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ColorSelect
