import { createMuiTheme } from "@material-ui/core"
import { colors } from "./colors"
export const theme = createMuiTheme({
  palette: {
    ...colors,
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    h6: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 600,
    },
    caption: {
      fontSize: "12px",
    },
    button: {
      fontWeight: "bold",
      fontSize: "16px",
    },
  },
  overrides: {
    MuiTab: {
      root: {
        minWidth: "50px",
        margin: "20px 0",
        borderRadius: "100px",
        backgroundColor: colors.white,
        color: colors.black,
        "&$selected": {
          backgroundColor: colors.black,
          color: colors.white,
        },
      },
      wrapper: {
        color: "inherit",
      },
    },
    MuiTabs: {
      root: {
        flex: "0 0 auto",
      },
      indicator: {
        backgroundColor: colors.black,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0 6px 9px 0 rgba(144,164,183,0.22)",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "50px",
      },
      outlined: {
        backgroundColor: colors.white,
        color: colors.primary.main,
        border: "none",
        boxShadow: "0 10px 10px 0 rgba(0,0,0,0.12)",
        width: "170px",
        marginLeft: "20px",
        fontWeight: 600,
        padding: "15px",
      },
      contained: {
        padding: "10px 20px",
      },
    },
    MuiTypography: {
      paragraph: {
        marginBottom: "6px",
      },
    },
    MuiAutocomplete: {
      clearIndicator: {
        visibility: "visible",
      },
    },
  },
})
