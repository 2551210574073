import React from "react"
import { connect } from "react-redux"
import { Grid } from "@material-ui/core"
import t from "i18n"
import { closeZoneList, openZoneList, getZoneList } from "ducks/fields/zone"
import FieldAutoComplete from "./FieldAutoComplete"

const Zone = ({
  bevQuotation,
  zoneField,
  closeZoneList,
  getZoneList,
  openZoneList,
}) => {
  const { bevProductData = {} } = bevQuotation
  const { zone, color: colorId } = bevProductData
  const { isLoading } = zoneField
  const shouldFetchList = !isLoading

  return (
    <Grid item xs={8}>
      <FieldAutoComplete
        fullWidth
        required
        field={zoneField}
        fieldValue={zone}
        fieldName="zone"
        label={t("zoneField")}
        getFieldList={({ zone }) => getZoneList({ colorId, zone })}
        closeFieldList={() => closeZoneList()}
        shouldFetchList={shouldFetchList}
        bevQuotation={bevQuotation}
        openFieldList={openZoneList}
      />
    </Grid>
  )
}

export default connect(
  state => ({
    zoneField: state.fields.zone,
  }),
  { closeZoneList, openZoneList, getZoneList },
)(Zone)
