import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

const { reducer } = createSlice({
  name: "finderStatus",
  initialState: null,
  reducers: {
    setBevQuotationStatus: _.identity,
  },
})

export default reducer
