import React from "react"
import { formats } from "utils/format"
import GenericSelect from "./GenericSelect"
import t from "i18n"

const FormatSelect = props => (
  <GenericSelect
    items={formats}
    displayItem={value => t("volumeText", { volume: value })}
    {...props}
  />
)

export default FormatSelect
