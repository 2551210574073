import React from "react"
import { connect } from "react-redux"
import t from "i18n"
import { closeCuveeList, openCuveeList, getCuveeList } from "ducks/fields/cuvee"
import { setBevProductData } from "ducks/bevProductData/actions"
import FieldAutoComplete from "./FieldAutoComplete"

const Cuvee = ({
  cuveeField,
  closeCuveeList,
  bevQuotation,
  getCuveeList,
  openCuveeList,
}) => {
  const { bevProductData = {} } = bevQuotation
  const { cuvee, zone, producer, color: colorId } = bevProductData
  const zoneId = zone?.id
  const producerId = producer?.id
  const { isOpen, isLoading } = cuveeField
  const shouldFetchList = !!producerId && !isLoading

  return (
    <FieldAutoComplete
      fullWidth
      field={cuveeField}
      fieldValue={cuvee}
      fieldName="cuvee"
      label={t("cuveeField")}
      open={isOpen}
      getFieldList={({ cuvee }) =>
        getCuveeList({ colorId, zoneId, producerId, cuvee })
      }
      shouldFetchList={shouldFetchList}
      openFieldList={openCuveeList}
      closeFieldList={closeCuveeList}
      bevQuotation={bevQuotation}
      fetchListOnFocus
    />
  )
}

export default connect(
  state => ({
    cuveeField: state.fields.cuvee,
  }),
  {
    closeCuveeList,
    setBevProductData,
    getCuveeList,
    openCuveeList,
  },
)(Cuvee)
