import React from "react"
import { Button, Typography } from "@material-ui/core"
import styled from "styled-components"
import t from "i18n"
import Icon from "components/Icon"
import { connect } from "react-redux"
import {
  nextQuotationId,
  previousQuotationId,
} from "ducks/currentQuotation/bevQuotations"
import { Link } from "react-router-dom"
import { getUri, Path } from "utils/router"

const NavigationContainer = styled.div`
  margin: 0.5vh 0;
  display: flex;
  justify-content: flex-end;
`

const RightIcon = styled(Icon)`
  margin-left: 10px;
`

const LeftIcon = styled(Icon)`
  margin-right: 10px;
`
const StyledButton = styled(Button)`
  width: 190px;
  display: flex;
  justify-content: center;
`
const QuotationNavigation = ({ currentQuotationId, nextId, previousId }) => {
  return (
    <NavigationContainer>
      <StyledButton
        variant="outlined"
        component={previousId && Link}
        to={
          previousId &&
          getUri({
            path: Path.quotationWine,
            bevQuotationId: previousId,
            currentQuotationId,
          })
        }
        disabled={!previousId}
      >
        <LeftIcon name="arrow-left-3" color="inherit" size={14} />
        <Typography variant="button">{t("previousWineButton")}</Typography>
      </StyledButton>

      <StyledButton
        variant="outlined"
        component={nextId && Link}
        to={
          nextId &&
          getUri({
            path: Path.quotationWine,
            bevQuotationId: nextId,
            currentQuotationId,
          })
        }
        disabled={!nextId}
      >
        <Typography variant="button">{t("nextWineButton")}</Typography>
        <RightIcon name="arrow-right-3" color="inherit" size={14} />
      </StyledButton>
    </NavigationContainer>
  )
}

export default connect((state, props) => ({
  nextId: nextQuotationId(state, props),
  previousId: previousQuotationId(state, props),
}))(QuotationNavigation)
