import React from "react"
import styled from "styled-components"
import t from "i18n"
import Icon from "../Icon"
import { Typography } from "@material-ui/core"
import { colors } from "../Theme/colors"

const FooterContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
`

const StyledLink = styled.a`
  text-decoration: none;
  color: ${colors.text.secondary};
  :hover {
    b {
      color: ${colors.text.primary};
    }
  }
`
const FooterText = ({ text }) => {
  const [madeWith, iconName, byBrandName] = text.split("|")
  const [by, brandName] = byBrandName.split(" ")

  return (
    <>
      <StyledLink
        href="https://www.matcha.wine/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {`${madeWith} `}
        <Icon name={iconName} color={colors.text.secondary} />
        {` ${by} `}
        <b>{brandName}</b>
      </StyledLink>
    </>
  )
}

const Footer = () => {
  const footerText = t("footer")

  return (
    <FooterContainer>
      <Typography
        variant="body2"
        component="span"
        color="textSecondary"
        align="center"
      >
        <FooterText text={footerText} />
      </Typography>
    </FooterContainer>
  )
}

export default Footer
