import { createAction } from "@reduxjs/toolkit"

const prepareGetBevQuotationSuccess = ({
  response: { data: bevQuotation, statusCode },
}) => ({
  payload: {
    bevQuotation: {
      ...bevQuotation,
      hasTooMuchSearchedBev: statusCode === 413,
    },
    statusCode,
  },
})

export const saveBevQuotationAction = createAction(
  "currentQuotation/bevQuotations/saveBevQuotation",
)

export const saveBevQuotationSuccess = createAction(
  "currentQuotation/bevQuotations/saveBevQuotationSuccess",
  prepareGetBevQuotationSuccess,
)
export const saveBevQuotationError = createAction(
  "currentQuotation/bevQuotations/saveBevQuotationError",
)

export const getBevQuotation = createAction(
  "currentQuotation/bevQuotations/getBevQuotation",
)

export const getBevQuotationSuccess = createAction(
  "currentQuotation/bevQuotations/getBevQuotationSuccess",
  prepareGetBevQuotationSuccess,
)

export const getBevQuotationError = createAction(
  "currentQuotation/bevQuotations/getBevQuotationError",
)
