import React from "react"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import _ from "lodash"
import { colors } from "../../Theme/colors"

const ColorGrid = styled(props => (
  <Grid
    {..._.omit(props, ["isHeaderSection", "status", "rounded", "withShadow"])}
  />
))`
  background-color: ${({ status }) => status && colors[status]};
  margin-right: 7px;
  border-radius: ${({ rounded }) => rounded && "4px"};
  box-shadow: ${({ withShadow }) =>
    withShadow && "0 6px 9px 0 rgba(144, 164, 183, 0.22)"};
`

const FullHeightGrid = styled(Grid)`
  height: 100%;
`

const QuotationWineLineSection = props => {
  const { children } = props

  return (
    <ColorGrid item zeroMinWidth {...props}>
      <FullHeightGrid
        container
        wrap="nowrap"
        alignItems="center"
        justify="center"
      >
        {children}
      </FullHeightGrid>
    </ColorGrid>
  )
}

export default QuotationWineLineSection
