import _ from "lodash"

export const getFieldListHandler = state => ({
  ...state,
  isLoading: true,
  list: [],
})

export const getFieldListErrorHandler = state => ({
  ...state,
  isLoading: false,
})

export const closeFieldListhandler = state => ({
  ...state,
  isOpen: false,
  list: [],
})

export const openFieldListHandler = state => ({
  ...state,
  isOpen: true,
})

export const getFieldListSuccess = fieldListName => ({
  reducer: (state, { payload }) => ({
    ...state,
    isLoading: false,
    list: payload[fieldListName],
  }),
  prepare: ({ response }) => {
    const { data } = response

    return { payload: { [fieldListName]: data } }
  },
})

export const debounceGetFieldList = getFieldListAction => {
  const debouncedGetFieldListAction = _.debounce(
    (dispatch, ...args) => dispatch(getFieldListAction(...args)),
    1000,
  )

  return (...args) => dispatch => debouncedGetFieldListAction(dispatch, ...args)
}

export const getFieldValue = value => (_.trim(value) === "" ? null : value)
