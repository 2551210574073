import t from "i18n"

export const colors = [
  { id: "R", name: t("colorR") },
  { id: "B", name: t("colorB") },
  { id: "O", name: t("colorO") },
  { id: "Z", name: t("colorZ") },
]

export const getColorNameFromId = id => (id ? t(`color${id}`) : "")
