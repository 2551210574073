import { createSlice } from "@reduxjs/toolkit"
import { selectSearchedBevProductData } from "./actions"
import { selectSearchedBevProductDataHandler } from "../../utils/bevProductData"

const { reducer } = createSlice({
  name: "pricings",
  initialState: [],
  reducers: {},
  extraReducers: {
    [selectSearchedBevProductData]: selectSearchedBevProductDataHandler(
      "pricings",
    ),
  },
})

export default reducer
