import React from "react"
import Typography from "@material-ui/core/Typography"
import t from "i18n"
import { Grid } from "@material-ui/core"
import styled from "styled-components"

const HeaderContainer = styled(Grid)`
  display: flex;
  align-items: center;
  flex: 0 0 120px;
  position: relative;
`

const MainItem = styled(Grid)`
  flex: 1 1 100%;
`
const LeftComponentGrid = styled(Grid)`
  z-index: 1;
`

const RightComponentGrid = styled(Grid)`
  flex: 2 0 65%;
`
const Header = ({ leftComponent, title, rightComponent }) => {
  return (
    <HeaderContainer container wrap="nowrap">
      <LeftComponentGrid item>{leftComponent}</LeftComponentGrid>
      <MainItem item>
        <Typography variant="h5" align="center">
          {title || t("pageTitle")}
        </Typography>
      </MainItem>
      {rightComponent && (
        <RightComponentGrid item>{rightComponent}</RightComponentGrid>
      )}
    </HeaderContainer>
  )
}

export default Header
