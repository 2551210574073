import React from "react"
import { Typography } from "@material-ui/core"
import styled from "styled-components"
import { getPriceText } from "utils/price"
import t from "i18n"

const PricingsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SellersContainer = styled.div`
  margin: 0 40px 10px 40px;
`

const UnderlinedTypography = styled(Typography)`
  text-decoration: underline;
`
const Pricings = ({ bevProductData }) => {
  const { pricings, numOffers } = bevProductData

  return (
    <PricingsContainer>
      <UnderlinedTypography paragraph>
        {t(
          numOffers === 0
            ? "marketPriceTooltipNoOffers"
            : "marketPriceTooltipTitle",
        )}
      </UnderlinedTypography>
      {numOffers > 0 && (
        <>
          <Typography>{t("sellersTooltipText", { numOffers })}</Typography>
          <SellersContainer>
            {pricings.map(({ price, source }, index) => (
              <Typography key={index}>
                {source.name} - {getPriceText(price)}€
              </Typography>
            ))}
          </SellersContainer>
        </>
      )}
    </PricingsContainer>
  )
}

export default Pricings
