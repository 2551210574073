import React from "react"
import { createGlobalStyle } from "styled-components"
import { colors } from "../Theme/colors"

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
  @import url('https://resources.matcha.wine/matcha-icon-font/style.css');
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: Open sans, sans-serif;
    color: ${colors.black};
    background-color: ${colors.white};
  }
`

const InitGlobalStyle = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
)

export default InitGlobalStyle
