import { createAction } from "@reduxjs/toolkit"
import { immutableSet } from "../../utils/reduxUtils"
import _ from "lodash"

const BevProductDataFieldsToCleanByFieldName = {
  zone: ["bevProductData.cuvee"],
  producer: ["bevProductData.cuvee"],
}
export const selectSearchedBevProductData = createAction(
  "bevProductData/selectSearchedBevProductData",
  ({ bevQuotation, bevProductData, currentQuotationId }) => ({
    payload: {
      bevQuotation: { ...bevQuotation, bevProductData },
      currentQuotationId,
    },
  }),
)

export const setBevProductData = createAction(
  "bevProductData/setBevProductData",
  ({ bevQuotation, fieldName, fieldValue, ...rest }) => {
    const newBevQuotation = immutableSet(
      bevQuotation,
      `bevProductData.${fieldName}`,
      fieldValue,
    )

    return {
      payload: {
        ...rest,
        bevQuotation: _.omit(
          newBevQuotation,
          BevProductDataFieldsToCleanByFieldName[fieldName],
        ),
      },
    }
  },
)
