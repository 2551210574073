export const colors = {
  white: "#FFFFFF",
  pageBackground: "#FDFDFD",
  primary: {
    main: "#db414c",
    light: "#E09EA9",
  },
  secondary: {
    main: "#9e1c31",
    light: "#E09EA9",
  },
  text: {
    primary: "#2F2C2A",
    secondary: "#8A9296",
  },
  abandoned: "#c1bfbf",
  matched: "#c9e2cc",
  notMatched: "#fe9494",
  black: "#1E232D",
}
