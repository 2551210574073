import { combineReducers, createSelector } from "@reduxjs/toolkit"
import rawInput from "./rawInput"
import name from "./name"
import bevQuotations from "./bevQuotations"

export const quotationName = (state = {}) => state.currentQuotation.name
export const quotationRawInput = (state = {}) => state.currentQuotation.rawInput
export const currentQuotation = (state = {}) => state.currentQuotation

export const isValidQuotationInput = createSelector(
  quotationName,
  quotationRawInput,
  (quotationName, quotationRawInput) => !(quotationRawInput && quotationName),
)

export default combineReducers({ rawInput, name, bevQuotations })
