import React, { useEffect } from "react"
import { getAuthenticatedUser } from "./auth"
import { connect } from "react-redux"

import {
  authenticate as authenticateAction,
  authenticateError,
  authenticateSuccess,
  bypassAuthentication,
} from "../../ducks/user/actions"
import { serializeError } from "serialize-error"
import { redirectToOldUrl, saveRedirectUrl } from "../../utils/redirectSave"

const InitAuth = ({
  children,
  authenticateAction,
  authenticateSuccess,
  authenticateError,
  bypassAuthentication,
  clientId,
}) => {
  useEffect(() => {
    if (clientId) redirectToOldUrl()
  }, [clientId])

  useEffect(() => {
    if (!clientId) {
      const forcedClientId = process.env.REACT_APP_CLIENT_ID

      if (forcedClientId) bypassAuthentication({ clientId: forcedClientId })
      else {
        saveRedirectUrl()
        authenticateAction()
        getAuthenticatedUser()
          .then(user => authenticateSuccess(user))
          .catch(e => {
            console.error(e)
            authenticateError({ error: serializeError(e) })
          })
      }
    }
  }, [
    authenticateAction,
    authenticateError,
    authenticateSuccess,
    bypassAuthentication,
    clientId,
  ])

  return <>{clientId ? children : null}</>
}

export default connect(state => ({ clientId: state.user.clientId }), {
  authenticateAction,
  authenticateSuccess,
  authenticateError,
  bypassAuthentication,
})(InitAuth)
