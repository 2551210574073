import _ from "lodash"
import queryString from "query-string"

const apiUrl = "https://engine-proxy.matcha.wine"
const rootUri = "finder"

class ApiError extends Error {
  constructor(message, code) {
    super(message)
    this.code = code
  }
}

const getUri = (...args) => _.compact([rootUri, ...args]).join("/")

export const getApiClient = ({ env, user: { clientId, email: userId } }) => {
  const getUrl = (uri, queryParams) => {
    const queryParamsString = queryString.stringify({
      ...queryParams,
      uri,
      env,
      clientId,
      userId,
    })
    return `${apiUrl}?${queryParamsString}`
  }

  const fetchApi = async (uri, options = {}) => {
    const { method = "GET", queryParams, ...rest } = options
    const url = getUrl(uri, queryParams)

    const response = await fetch(url, { method, ...rest })

    const { ok, status: statusCode, headers } = response
    const parsedResponse = await response.json()

    const { errorCode, message } = parsedResponse

    if (!ok && statusCode !== 413) throw new ApiError(message, errorCode)

    return {
      ...parsedResponse,
      statusCode,
      headers,
      request: { url, options },
    }
  }

  const getQuotations = async () => {
    const uri = getUri("quotations")

    return await fetchApi(uri)
  }

  const getQuotation = async currentQuotationId => {
    const uri = getUri("quotations", currentQuotationId)

    return await fetchApi(uri)
  }

  const createQuotation = async quotation => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
    const options = {
      method: "POST",
      headers,
      body: JSON.stringify(quotation),
    }

    const uri = getUri("quotations")

    return await fetchApi(uri, options)
  }

  const saveBevQuotation = async ({ bevQuotation, currentQuotationId }) => {
    const { id } = bevQuotation
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    }

    const options = {
      method: "PUT",
      headers,
      body: JSON.stringify(bevQuotation),
    }

    const uri = getUri("quotations", currentQuotationId, "bev_quotations", id)

    return await fetchApi(uri, options)
  }

  const getBevQuotation = async ({ currentQuotationId, bevQuotationId }) => {
    const uri = getUri(
      "quotations",
      currentQuotationId,
      "bev_quotations",
      bevQuotationId,
    )

    return await fetchApi(uri)
  }

  const getZones = async ({ colorId, zone }) => {
    const uri = getUri("zones")
    const options = {
      queryParams: {
        colorId,
        zone,
      },
    }

    return await fetchApi(uri, options)
  }

  const getDomains = async ({ colorId, zoneId, producer }) => {
    const uri = getUri("producers")
    const options = {
      queryParams: {
        colorId,
        zoneId,
        producer,
      },
    }
    return await fetchApi(uri, options)
  }

  const getCuvees = async ({ colorId, zoneId, producerId, cuvee }) => {
    const uri = getUri("cuvees")
    const options = {
      queryParams: {
        colorId,
        zoneId,
        producerId,
        cuvee,
      },
    }
    return await fetchApi(uri, options)
  }

  return {
    getQuotations,
    getQuotation,
    createQuotation,
    saveBevQuotation,
    getZones,
    getDomains,
    getCuvees,
    getBevQuotation,
  }
}
