import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import { immutableSet } from "utils/reduxUtils"
import { setBevProductData } from "../actions"
import { setProductDataReducerFactory } from "../../../utils/bevProductData"

const {
  reducer,
  actions: { modifyBevQuotationVolume },
} = createSlice({
  name: "volume",
  initialState: null,
  reducers: {
    modifyBevQuotationVolume: {
      reducer: _.identity,
      prepare: ({ bevQuotation, volume, currentQuotationId }) => ({
        payload: {
          currentQuotationId,
          bevQuotation: immutableSet(
            bevQuotation,
            "bevProductData.productInfo.volume",
            volume,
          ),
        },
      }),
    },
  },
  extraReducers: {
    [setBevProductData]: setProductDataReducerFactory("productInfo.volume"),
  },
})

export { modifyBevQuotationVolume }

export default reducer
