import React from "react"

// matcha-ycon is written with y to avoid client css rules
const Icon = ({
  name,
  className,
  Tag = "span",
  prefix = "matcha-ycon",
  color = "black",
  size,
}) => {
  const iconClassName = `${prefix} ${prefix}-${name} ${className}`

  return (
    <Tag
      className={iconClassName}
      aria-hidden="true"
      style={{
        color,
        fontSize: size,
      }}
    >
      <span className="path1" />
      <span className="path2" />
      <span className="path3" />
    </Tag>
  )
}

export default Icon
