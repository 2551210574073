import React from "react"
import { Typography, Tooltip } from "@material-ui/core"
import { getPriceText } from "utils/price"
import styled from "styled-components"
import Pricings from "./Pricings"
import Icon from "../../Icon"
import t from "i18n"
const FullWidthHeight = styled.div`
  width: 100%;
  height: 100%;
`

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`
const FlexColumn = styled(FullWidthHeight)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const MarginIcon = styled(Icon)`
  margin-left: 5px;
`
const TooltipChild = React.forwardRef((props, ref) => (
  <FullWidthHeight {...props} ref={ref} />
))

const PriceWrapper = ({ numOffers, bevProductData, children }) =>
  numOffers >= 0 ? (
    <Tooltip title={<Pricings bevProductData={bevProductData} />}>
      <TooltipChild>{children}</TooltipChild>
    </Tooltip>
  ) : (
    <>{children}</>
  )

//TODO: write tests
const Price = ({ bevProductData = {} }) => {
  const { numOffers, marketPrice } = bevProductData

  return marketPrice ? (
    <PriceWrapper bevProductData={bevProductData} numOffers={numOffers}>
      <FlexColumn>
        <Typography variant="subtitle2" noWrap align="center" paragraph>
          {marketPrice ? `${getPriceText(marketPrice)} €` : "-"}
        </Typography>

        {numOffers >= 0 && (
          <FlexDiv>
            <Typography variant="caption">
              {t("numOffersText", { numOffers })}
            </Typography>
            <MarginIcon name="down-chevron" size={10} />
          </FlexDiv>
        )}
      </FlexColumn>
    </PriceWrapper>
  ) : (
    "-"
  )
}

export default Price
