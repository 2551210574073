import { createSlice } from "@reduxjs/toolkit"
import { selectSearchedBevProductData, setBevProductData } from "./actions"
import {
  selectSearchedBevProductDataHandler,
  setProductDataReducerFactory,
} from "utils/bevProductData"

const initialState = null

const { reducer } = createSlice({
  name: "zone",
  initialState,
  reducers: {},
  extraReducers: {
    [setBevProductData]: setProductDataReducerFactory("zone"),
    [selectSearchedBevProductData]: selectSearchedBevProductDataHandler("zone"),
  },
})

export default reducer
