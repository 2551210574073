import { createSlice } from "@reduxjs/toolkit"
import { selectSearchedBevProductData, setBevProductData } from "./actions"
import {
  selectSearchedBevProductDataHandler,
  setProductDataReducerFactory,
} from "utils/bevProductData"

const initialState = null
const { reducer } = createSlice({
  name: "cuvee",
  initialState,
  reducers: {},
  extraReducers: {
    [setBevProductData]: setProductDataReducerFactory("cuvee"),
    [selectSearchedBevProductData]: selectSearchedBevProductDataHandler(
      "cuvee",
    ),
  },
})

export default reducer
