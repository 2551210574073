import React from "react"
import { Button, Typography } from "@material-ui/core"
import styled from "styled-components"
import t from "i18n"
import { connect } from "react-redux"
import {
  abandonBevQuotation,
  reenableBevQuotation,
} from "ducks/bevQuotation/userStatus"
import { bevQuotationFromId, isAbandonedBevQuotation } from "ducks/bevQuotation"
import { Link } from "react-router-dom"
import { getUri, Path, useUri } from "utils/router"

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0;
`
const QuotationFooter = ({
  bevQuotation = {},
  isAbandoned,
  currentQuotationId,
  abandonBevQuotation,
  reenableBevQuotation,
}) => {
  const uriState = useUri()
  return (
    <FooterContainer>
      <Button
        variant="outlined"
        component={Link}
        to={getUri({
          path: Path.quotationResult,
          ...uriState,
        })}
      >
        <Typography variant="button">{t("okButton")}</Typography>
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          isAbandoned
            ? reenableBevQuotation({ bevQuotation, currentQuotationId })
            : abandonBevQuotation({ bevQuotation, currentQuotationId })
        }}
      >
        <Typography variant="button">
          {t(isAbandoned ? "reenableButton" : "abandonButton")}
        </Typography>
      </Button>
    </FooterContainer>
  )
}

export default connect(
  (state, props) => {
    const { bevQuotation } = bevQuotationFromId(state, props)
    const isAbandoned = isAbandonedBevQuotation(bevQuotation)

    return { bevQuotation, isAbandoned }
  },
  {
    abandonBevQuotation,
    reenableBevQuotation,
  },
)(QuotationFooter)
