import React from "react"
import { Tab, Tabs } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { Status } from "ducks/currentQuotation/bevQuotations"
import { useUri } from "utils/router"
import { statusList } from "utils/status"

const Filters = () => {
  const history = useHistory()
  const { status: currentStatus = Status.all, pathname } = useUri()

  return (
    <Tabs value={currentStatus} centered>
      {statusList.map(({ id, name }, index) => (
        <Tab
          label={name}
          value={id}
          key={index}
          onClick={() => history.push(`${pathname}?status=${id}`)}
        />
      ))}
    </Tabs>
  )
}

export default Filters
