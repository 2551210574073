import { createSlice } from "@reduxjs/toolkit"
import { selectSearchedBevProductData } from "../bevProductData"
import _ from "lodash"

const { reducer } = createSlice({
  name: "searchedBevProductDatas",
  initialState: null,
  reducers: {},
  extraReducers: {
    [selectSearchedBevProductData]: (
      state,
      {
        payload: {
          bevQuotation: {
            bevProductData: { id },
          },
        },
      },
    ) =>
      _.filter(
        state,
        searchedBevProductData => searchedBevProductData?.id !== id,
      ),
  },
})

export default reducer
