import React from "react"
import Header from "../Header"
import Footer from "../Footer"

const Page = ({ children, headerProps = {} }) => (
  <>
    <Header {...headerProps} />
    {children}
    <Footer />
  </>
)

export default Page
