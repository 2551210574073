import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { Path } from "../../utils/router"
import ResultPage from "../ResultPage"
import CreateQuotationPage from "../CreateQuotationPage"
import LoadingPage from "../LoadingPage"
import CreateQuotationRedirect from "../CreateQuotationRedirect"
import QuotationsList from "../QuotationsList"
const QuotationRoutes = () => (
  <Switch>
    <Route path={Path.newQuotation} component={CreateQuotationRedirect} />
    <Route path={Path.newQuotationWithId} component={CreateQuotationPage} />
    <Route path={Path.loadingQuotation} component={LoadingPage} />
    <Route path={Path.quotationResult} component={ResultPage} />
    <Route component={QuotationsList} />
    <Redirect to={Path.newQuotation} />
  </Switch>
)

export default QuotationRoutes
