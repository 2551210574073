import { combineReducers } from "redux"
import { createSelector } from "@reduxjs/toolkit"
import finderStatus from "./finderStatus"
import id from "./id"
import rawInput from "./rawInput"
import isBevMatched from "./isBevMatched"
import searchedBevProductDatas from "./searchedBevProductDatas"
import bevProductData from "ducks/bevProductData"
import userStatus from "./userStatus"
import hasTooMuchSearchedBev from "./hasTooMuchSearchedBev"
import { Status } from "../currentQuotation/bevQuotations"

export const getBevQuotationById = (bevQuotationsById, props) =>
  bevQuotationsById?.[props?.bevQuotation?.id]

const getBevQuotationUserStatus = (bevQuotation = {}) => bevQuotation.userStatus

const bevQuotationUserStatus = createSelector(
  getBevQuotationById,
  getBevQuotationUserStatus,
)

const bevQuotationFinderStatus = createSelector(
  getBevQuotationById,
  (bevQuotation = {}) => bevQuotation.finderStatus,
)

export const isAbandonedBevQuotation = createSelector(
  getBevQuotationUserStatus,
  userStatus => userStatus === Status.abandoned,
)

export const bevQuotationFromId = (state, props) => {
  const bevQuotation = getBevQuotationById(
    state.currentQuotation.bevQuotations.byId,
    {
      bevQuotation: { id: props.bevQuotationId },
    },
  )

  return {
    bevQuotation,
  }
}

export const bevQuotationStatus = createSelector(
  bevQuotationUserStatus,
  bevQuotationFinderStatus,
  (userStatus, finderStatus) => userStatus || finderStatus,
)

export default combineReducers({
  id,
  rawInput,
  isBevMatched,
  bevProductData,
  searchedBevProductDatas,
  finderStatus,
  userStatus,
  hasTooMuchSearchedBev,
})
