import React from "react"
import InitMaterialUI from "./InitMaterialUi"
import InitRouter from "./InitRouter"
import InitGlobalStyle from "./InitGlobalStyle"
import InitReduxStore from "./InitReduxStore"
import InitAuth from "../InitAuth"
import Message from "../Message"

const AppInitiator = ({ children }) => (
  <InitReduxStore>
    <InitMaterialUI>
      <InitGlobalStyle>
        <InitRouter>
          <Message />
          <InitAuth>{children}</InitAuth>
        </InitRouter>
      </InitGlobalStyle>
    </InitMaterialUI>
  </InitReduxStore>
)

export default AppInitiator
