import { createSlice } from "@reduxjs/toolkit"
import t from "i18n"
import { createQuotationError } from "../currentQuotation/actions"
import { authenticateError } from "../user/actions"
import {
  getBevQuotationSuccess,
  saveBevQuotationSuccess,
} from "../bevQuotation/actions"
import { abandonBevQuotation } from "../bevQuotation/userStatus"

const handleDefaultMessage = (state, { type }) => t(type)

const handleSuccessMessage = (
  state,
  {
    type,
    payload: {
      statusCode,
      bevQuotation: { userStatus },
    },
  },
) => (statusCode === 413 && !userStatus ? t(type) : state)

const {
  reducer,
  actions: { closeMessage },
} = createSlice({
  name: "message",
  initialState: null,
  reducers: {
    closeMessage: () => null,
  },
  extraReducers: {
    [createQuotationError]: handleDefaultMessage,
    [authenticateError]: handleDefaultMessage,
    [saveBevQuotationSuccess]: handleSuccessMessage,
    [getBevQuotationSuccess]: handleSuccessMessage,
    [abandonBevQuotation]: handleDefaultMessage,
  },
})

export { closeMessage }

export default reducer
