import React from "react"
import { ProducerAndCuveeCell } from "../TableCell"
import { getProducerAndCuvee } from "utils/bev"
import ClampedTypo from "../../ClampedTypo"

const ProducerAndCuvee = ({ producer = {}, cuvee = {} }) => {
  const producerAndCuvee = getProducerAndCuvee({ producer, cuvee })
  return (
    <ProducerAndCuveeCell>
      <ClampedTypo variant="body2" noWrap>
        {producerAndCuvee}
      </ClampedTypo>
    </ProducerAndCuveeCell>
  )
}

export default ProducerAndCuvee
