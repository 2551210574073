import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { Path } from "../utils/router"
import QuotationRoutes from "./QuotationRoutes"
import styled from "styled-components"

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`

const App = () => {
  return (
    <AppContainer>
      <Switch>
        <Route path={Path.quotation} component={QuotationRoutes} />
        <Redirect to={Path.quotation} />
      </Switch>
    </AppContainer>
  )
}

export default App
