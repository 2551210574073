import { createSlice } from "@reduxjs/toolkit"
import {
  selectSearchedBevProductDataHandler,
  setProductDataReducerFactory,
} from "utils/bevProductData"
import { selectSearchedBevProductData } from "./actions"
import { setBevProductData } from "./actions"

const initialState = null

const { reducer } = createSlice({
  name: "producer",
  initialState,
  reducers: {},
  extraReducers: {
    [setBevProductData]: setProductDataReducerFactory("producer"),
    [selectSearchedBevProductData]: selectSearchedBevProductDataHandler(
      "producer",
    ),
  },
})

export default reducer
