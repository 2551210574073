import React from "react"
import { OriginalSearchCell } from "../TableCell"
import ClampedTypo from "components/ClampedTypo"

const OriginalSearch = ({ rawInput }) => {
  return (
    <OriginalSearchCell>
      <ClampedTypo variant="body2">{rawInput}</ClampedTypo>
    </OriginalSearchCell>
  )
}

export default OriginalSearch
