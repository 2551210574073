import React from "react"
import { connect } from "react-redux"
import { Snackbar, Fade, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { closeMessage } from "ducks/message"

const Message = ({ closeMessage, currentMessage }) => {
  return (
    <Snackbar
      disableWindowBlurListener={true}
      autoHideDuration={5000}
      transitionDuration={1000}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      message={currentMessage}
      open={!!currentMessage}
      onClose={(e, reason) => {
        if (reason !== "clickaway") closeMessage()
      }}
      action={[
        <IconButton
          key={"close"}
          aria-label={"Close"}
          color={"inherit"}
          onClick={() => closeMessage()}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )
}
export default connect(
  state => ({
    currentMessage: state.message,
  }),
  { closeMessage },
)(Message)
