import { createSlice, createSelector } from "@reduxjs/toolkit"
import {
  createQuotationSuccess,
  getQuotationSuccess,
  onCloseQuotationResultPage,
} from "./actions"
import _ from "lodash"
import bevQuotation, { bevQuotationStatus } from "ducks/bevQuotation"
import {
  saveBevQuotationSuccess,
  getBevQuotationSuccess,
} from "../bevQuotation/actions"
import { getIndexedObjectList } from "../../utils/reduxUtils"

export const Status = {
  all: "all",
  notMatched: "notMatched",
  matched: "matched",
  abandoned: "abandoned",
}

export const bevQuotationsById = state =>
  state.currentQuotation.bevQuotations.byId

export const bevQuotationsAllIds = state =>
  state.currentQuotation.bevQuotations.allIds

export const nextQuotationId = createSelector(
  bevQuotationsAllIds,
  (state, props) => props.bevQuotationId,
  (allIds, bevQuotationId) => {
    const bevQuotationIndex = _.indexOf(allIds, bevQuotationId)

    return allIds[bevQuotationIndex + 1]
  },
)

export const previousQuotationId = createSelector(
  bevQuotationsAllIds,
  (state, props) => props.bevQuotationId,
  (allIds, bevQuotationId) => {
    const bevQuotationIndex = _.indexOf(allIds, bevQuotationId)

    return allIds[bevQuotationIndex - 1]
  },
)

export const bevQuotationsLength = createSelector(
  bevQuotationsAllIds,
  bevQuotationsAllIds => bevQuotationsAllIds?.length,
)
export const allBevQuotations = createSelector(
  bevQuotationsById,
  bevQuotationsById => _.map(bevQuotationsById, _.identity),
)

export const bevQuotationsByStatus = status =>
  createSelector(
    allBevQuotations,
    bevQuotationsById,
    (allBevQuotations, bevQuotationsById) =>
      status === Status.all
        ? allBevQuotations
        : _.filter(
            allBevQuotations,
            bevQuotation =>
              status ===
              bevQuotationStatus(bevQuotationsById, { bevQuotation }),
          ),
  )

export const bevQuotationsByStatusFactory = status => (state, props) =>
  bevQuotationsByStatus(state, { ...props, status })

const handleReceivedQuotation = (
  state,
  {
    payload: {
      quotation: { bevQuotations },
    },
  },
) => getIndexedObjectList(bevQuotations)

const handleReceivedBevQuotation = (state, action) => {
  const { byId } = state
  const { payload } = action
  const bevQuotation = payload.bevQuotation
  const { id } = bevQuotation

  return {
    ...state,
    byId: {
      ...byId,
      [id]: bevQuotation,
    },
  }
}

const initialState = {
  byId: {},
  allIds: [],
}

const { reducer: bevQuotations } = createSlice({
  name: "bevQuotations",
  initialState,
  reducers: {},
  extraReducers: {
    [getQuotationSuccess]: handleReceivedQuotation,
    [createQuotationSuccess]: handleReceivedQuotation,
    [saveBevQuotationSuccess]: handleReceivedBevQuotation,
    [getBevQuotationSuccess]: handleReceivedBevQuotation,
    [onCloseQuotationResultPage]: () => initialState,
  },
})

const quotationActions = [
  getQuotationSuccess,
  createQuotationSuccess,
].map(action => action.toString())

export default (state, action) =>
  _.flow([
    state => bevQuotations(state, action),
    state => {
      const { byId } = state

      return {
        ...state,
        byId: {
          ...state.byId,
          ..._.mapValues(byId, quotation => {
            const id = _.get(action, "payload.bevQuotation.id")
            const isQuotationAction = quotationActions.includes(action.type)

            return id === quotation.id || isQuotationAction
              ? bevQuotation(quotation, action)
              : quotation
          }),
        },
      }
    },
  ])(state)
