import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

const {
  reducer,
  actions: {
    getQuotationsList,
    getQuotationsListError,
    getQuotationsListSuccess,
  },
} = createSlice({
  name: "quotations",
  initialState: [],
  reducers: {
    getQuotationsList: _.identity,
    getQuotationsListSuccess: {
      reducer: (state, { payload: { quotationsList } }) => quotationsList,
      prepare: ({ response }) => ({
        payload: {
          quotationsList: response.data,
        },
      }),
    },
    getQuotationsListError: _.identity,
  },
})

export { getQuotationsList, getQuotationsListError, getQuotationsListSuccess }

export default reducer
