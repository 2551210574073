import React from "react"
import { Card, Grid, Typography } from "@material-ui/core"
import styled from "styled-components"
import AssignmentIcon from "@material-ui/icons/Assignment"
import Icon from "../Icon"
import { Link } from "react-router-dom"
import { getUri, Path } from "utils/router"
import { getDateText } from "../../utils/date"

const StyledCard = styled(Card)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  padding: 15px 25px;
  display: flex;
  align-items: center;
  height: 75px;
  justify-content: space-between;
  margin-bottom: 15px;
`

const QuotationNameAndDateContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: unset;
`

const FullHeightGrid = styled(Grid)`
  height: 100%;
`
const QuotationCard = ({ quotation }) => {
  const { name, id, bevCount, lastModifiedDate } = quotation

  return (
    <StyledLink
      to={getUri({ path: Path.quotationResult, currentQuotationId: id })}
    >
      <StyledCard>
        <FullHeightGrid container>
          <FlexGrid item xs={1}>
            <AssignmentIcon fontSize="large" />
          </FlexGrid>
          <FlexGrid item xs={5}>
            <QuotationNameAndDateContainer>
              <Typography color="textSecondary">{name}</Typography>
              <Typography color="textSecondary" variant="caption">
                {getDateText(lastModifiedDate)}
              </Typography>
            </QuotationNameAndDateContainer>
          </FlexGrid>
          <FlexGrid item xs={5}>
            <Typography color="textSecondary">
              {bevCount} analyzed wines
            </Typography>
          </FlexGrid>
          <FlexGrid item xs={1}>
            <Icon name="next" size={26} />
          </FlexGrid>
        </FullHeightGrid>
      </StyledCard>
    </StyledLink>
  )
}

export default QuotationCard
