import { createSlice } from "@reduxjs/toolkit"
import { authenticateSuccess, bypassAuthentication } from "./actions"

const handleClientId = (state, { payload: { clientId } }) => clientId

const { reducer } = createSlice({
  name: "clientId",
  initialState: null,

  extraReducers: {
    [authenticateSuccess]: handleClientId,
    [bypassAuthentication]: handleClientId,
  },
})

export default reducer
