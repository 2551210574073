import { createSlice } from "@reduxjs/toolkit"
import {
  closeFieldListhandler,
  debounceGetFieldList,
  getFieldListErrorHandler,
  getFieldListHandler,
  getFieldListSuccess,
  openFieldListHandler,
} from "utils/fieldUtils"

const initialState = {
  isLoading: false,
  list: [],
  isOpen: false,
}

const {
  reducer,
  actions: {
    getCuveeListError,
    getCuveeListSuccess,
    closeCuveeList,
    getCuveeList: getCuveeListAction,
    openCuveeList,
  },
} = createSlice({
  name: "cuvee",
  initialState,
  reducers: {
    getCuveeList: getFieldListHandler,
    getCuveeListError: getFieldListErrorHandler,
    getCuveeListSuccess: getFieldListSuccess("cuveeList"),
    closeCuveeList: closeFieldListhandler,
    openCuveeList: openFieldListHandler,
  },
})

export const getCuveeList = debounceGetFieldList(getCuveeListAction)

export {
  openCuveeList,
  getCuveeListError,
  getCuveeListSuccess,
  closeCuveeList,
  getCuveeListAction,
}

export default reducer
