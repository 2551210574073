import React from "react"
import { connect } from "react-redux"
import { ActionCell } from "../TableCell"
import { Button } from "@material-ui/core"
import { useUri } from "../../../utils/router"
import { abandonBevQuotation } from "ducks/bevQuotation/userStatus"
import Icon from "../../Icon"
import styled from "styled-components"

const StyledIcon = styled(Icon)`
  font-size: 30px;
`

const Action = ({ status, bevQuotation, abandonBevQuotation }) => {
  const { currentQuotationId } = useUri()

  return (
    <ActionCell status={status} withShadow>
      <Button
        data-testid="abandonBevQuotationButton"
        onClick={e => {
          e.preventDefault()
          abandonBevQuotation({ bevQuotation, currentQuotationId })
        }}
      >
        <StyledIcon name="trash" />
      </Button>
    </ActionCell>
  )
}

export default connect(null, { abandonBevQuotation })(Action)
