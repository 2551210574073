import _ from "lodash"

export const selectSearchedBevProductDataHandler = key => (
  state,
  { payload },
) => _.get(payload, `bevQuotation.bevProductData.${key}`)

export const setProductDataReducerFactory = (key, defaultValue = null) => (
  state,
  { payload: { bevQuotation } },
) => _.get(bevQuotation, `bevProductData.${key}`, defaultValue)
