import React from "react"
import styled from "styled-components"
import { colors } from "../../Theme/colors"
import { Grid, Typography } from "@material-ui/core"
import QuotationWineLineSection from "../QuotationWineLine/QuotationWineLineSection"
import * as TableCells from "../TableCell"
import t from "i18n"

const { ActionCell, WineIndexCell } = TableCells

const TableHeaderUnderlined = styled(Grid)`
  border-bottom: 1px solid ${colors.text.secondary};
  height: 75px;
  width: calc(100% + 15px);
`

const TableHeaderCell = ({ cellName }) => {
  const Component = TableCells[`${cellName}Cell`]

  return (
    <Component>
      <Typography variant="subtitle1">{t(`column${cellName}`)}</Typography>
    </Component>
  )
}

const TableHeader = () => {
  return (
    <>
      <TableHeaderUnderlined
        container
        justify="flex-start"
        wrap="nowrap"
        data-testid="quotationTableHeader"
      >
        <QuotationWineLineSection xs={3}>
          <WineIndexCell />
          <TableHeaderCell cellName="OriginalSearch" />
        </QuotationWineLineSection>

        <QuotationWineLineSection xs={10}>
          <TableHeaderCell cellName="ProducerAndCuvee" />

          <TableHeaderCell cellName="Zone" />

          <TableHeaderCell cellName="Color" />

          <TableHeaderCell cellName="Vintage" />

          <TableHeaderCell cellName="Format" />

          <TableHeaderCell cellName="Conditioning" />
        </QuotationWineLineSection>

        <QuotationWineLineSection xs={1}>
          <TableHeaderCell cellName="Price" />
        </QuotationWineLineSection>
        {/*<OfferSources />*/}

        <ActionCell />
      </TableHeaderUnderlined>
    </>
  )
}

export default TableHeader
