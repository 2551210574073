import { createAction } from "@reduxjs/toolkit"

export const setCurrentQuotation = createAction(
  "currentQuotation/setCurrentQuotation",
)

export const onCloseQuotationResultPage = createAction(
  "currentQuotation/onCloseQuotationResultPage",
)

export const createQuotationSuccess = createAction(
  "currentQuotation/createQuotationSuccess",
  ({ response: { data: quotation } }) => ({ payload: { quotation } }),
)

export const createQuotationError = createAction(
  "currentQuotation/createQuotationError",
)

export const createQuotationAction = createAction(
  "currentQuotation/createQuotationAction",
)

export const getQuotationAction = createAction(
  "currentQuotation/getQuotationAction",
)

export const getQuotationSuccess = createAction(
  "currentQuotation/getQuotationSuccess",
  ({ response: { data: quotation } }) => ({ payload: { quotation } }),
)

export const getQuotationActionError = createAction(
  "currentQuotation/getQuotationError",
)
