import asyncStringify from "csv-stringify/lib/sync"
import saveAs from "file-saver"
import t from "i18n"
import { getStatusText } from "../bev"
import { getColorNameFromId } from "../colors"
import { pricingsColumnsHeaders, getCsvPricings } from "../price"

const emptyValueCell = "-"

const getBevQuotationsLines = bevQuotations => {
  return bevQuotations.map(bevQuotation => {
    const { rawInput, bevProductData } = bevQuotation
    const {
      zone,
      producer,
      cuvee,
      vintage = emptyValueCell,
      color,
      marketPrice = emptyValueCell,
      productInfo,
      pricings,
    } = bevProductData

    return [
      rawInput,
      getStatusText(bevQuotation),
      producer?.name || emptyValueCell,
      cuvee?.selectorName || emptyValueCell,
      zone?.name || emptyValueCell,
      getColorNameFromId(color) || emptyValueCell,
      vintage,
      t("volumeText", { volume: productInfo.volume }),
      productInfo.conditioning,
      marketPrice,
      ...getCsvPricings(pricings),
    ]
  })
}

const headerLine = [
  t("columnOriginalSearch"),
  t("statusColumnTitle"),
  t("domaineField"),
  t("cuveeField"),
  t("columnZone"),
  t("columnColor"),
  t("columnVintage"),
  t("columnFormat"),
  t("columnConditioning"),
  t("columnPrice"),
  ...pricingsColumnsHeaders,
]

export const getQuotationsAsString = bevQuotations =>
  asyncStringify([headerLine, ...getBevQuotationsLines(bevQuotations)], {
    delimiter: ";",
  })

export const exportQuotationAsCsv = async ({
  quotationName,
  bevQuotations,
}) => {
  const csvAsString = await getQuotationsAsString(bevQuotations)
  const csvAsBlob = new Blob([csvAsString], { type: "text/plain" })

  saveAs(csvAsBlob, `${quotationName}.csv`, { type: "text/plain" })
}
