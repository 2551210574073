import { compile, match } from "path-to-regexp"
import queryString from "query-string"
import { useRouteMatch, useLocation } from "react-router-dom"
import history from "utils/history"
import _ from "lodash"

export const Path = {
  quotation: "/quotation",
  newQuotation: "/quotation/new",
  newQuotationWithId: "/quotation/:currentQuotationId/new",
  loadingQuotation: "/quotation/:currentQuotationId/loading",
  quotationResult: "/quotation/:currentQuotationId/result",
  quotationWine:
    "/quotation/:currentQuotationId/result/bev-quotation/:bevQuotationId",
  quotationExport: "/quotation/:currentQuotationId/result/export",
}

export const getUri = uriState => {
  const { path } = uriState

  const toPath = compile(path)
  const queryParams = _.omit(uriState, [
    "path",
    "currentQuotationId",
    "bevQuotationId",
  ])
  //The presence of the ? in the current location if no queryParams, makes the matchPath function fail
  const stringifiedQueryParams = _.isEmpty(queryParams)
    ? ""
    : `?${queryString.stringify(queryParams)}`

  return `${toPath(uriState)}${stringifiedQueryParams}`
}

export const matchPath = (uri, pathname) =>
  !!match(uri, {
    decode: decodeURIComponent,
  })(pathname)

export const redirectToUri = uriState => {
  const {
    location: { pathname },
  } = history
  const { currentQuotationId } = uriState
  const loadingScreenUri = getUri({
    currentQuotationId,
    path: Path.loadingQuotation,
  })
  const fromLoadingScreen = matchPath(loadingScreenUri, pathname)

  const redirect = fromLoadingScreen ? history.replace : history.push

  redirect(getUri(uriState))
}

export const useUri = () => {
  const { params } = useRouteMatch()
  const { pathname, search } = useLocation()
  const parsedSearch = queryString.parse(search)

  return { ...parsedSearch, ...params, pathname }
}

export const useSearch = search => queryString.parse(search)
