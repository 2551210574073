import React from "react"
import { connect } from "react-redux"
import { useUri } from "utils/router"
import { modifyBevQuotationConditioning } from "ducks/bevProductData/productInfo/conditioning"
import ConditioningSelect from "components/Select/ConditioningSelect"
import { ConditioningCell } from "../TableCell"

export const Conditioning = ({
  modifyBevQuotationConditioning,
  bevQuotation,
}) => {
  const { currentQuotationId } = useUri()
  const {
    id,
    bevProductData: { productInfo: { conditioning } } = {},
  } = bevQuotation

  return (
    <ConditioningCell>
      <ConditioningSelect
        value={conditioning}
        onChange={({ target: { value: conditioning } }) =>
          modifyBevQuotationConditioning({
            currentQuotationId,
            conditioning,
            bevQuotation,
          })
        }
        data-testid={`conditioningSelect-${id}`}
        itemTestId="conditioningItem"
        preventDefault
      />
    </ConditioningCell>
  )
}

export default connect(null, { modifyBevQuotationConditioning })(Conditioning)
