import { createSlice } from "@reduxjs/toolkit"
import { selectSearchedBevProductData } from "./actions"
import {
  selectSearchedBevProductDataHandler,
  setProductDataReducerFactory,
} from "utils/bevProductData"
import { setBevProductData } from "./actions"

const { reducer } = createSlice({
  name: "color",
  initialState: null,
  reducers: {},
  extraReducers: {
    [selectSearchedBevProductData]: selectSearchedBevProductDataHandler(
      "color",
    ),
    [setBevProductData]: setProductDataReducerFactory("color"),
  },
})

export default reducer
