import React from "react"
import { connect } from "react-redux"
import VintageSelect from "components/Select/VintageSelect"
import t from "i18n"
import { useUri } from "utils/router"
import { setBevProductData } from "ducks/bevProductData/actions"

const Vintage = ({ setBevProductData, bevQuotation }) => {
  const { bevProductData = {} } = bevQuotation
  const { vintage } = bevProductData
  const { currentQuotationId } = useUri()

  return (
    <VintageSelect
      value={vintage || ""}
      label={t("vintageField")}
      fullWidth
      required
      onChange={e =>
        setBevProductData({
          fieldName: "vintage",
          fieldValue: e.target.value,
          bevQuotation,
          currentQuotationId,
        })
      }
    />
  )
}

export default connect(null, { setBevProductData })(Vintage)
