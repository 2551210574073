import currentQuotation from "./currentQuotation"
import message from "./message"
import quotations from "./quotations"
import fields from "./fields"
import user from "./user"

export default {
  quotations,
  currentQuotation,
  message,
  fields,
  user,
}
