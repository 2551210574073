import React from "react"
import { connect } from "react-redux"
import { Grid, Paper } from "@material-ui/core"
import styled from "styled-components"
import { bevQuotationFromId } from "ducks/bevQuotation"
import WineQuotationStatus from "./WineQuotationStatus"
import OriginalSearch from "./OriginalSearch"
import Color from "./Color"
import Zone from "./Zone"
import Domain from "./Domain"
import Cuvee from "./Cuvee"
import Vintage from "./Vintage"
import Format from "./Format"
import Conditioning from "./Conditioning"

const PaddingPaper = styled(Paper)`
  padding: 2vh;
`

const QuotationCorrectionForm = ({ bevQuotation = {} }) => {
  const { rawInput } = bevQuotation

  return (
    <PaddingPaper>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs={2}>
          <WineQuotationStatus bevQuotation={bevQuotation} />
        </Grid>

        <Grid item xs={10}>
          <OriginalSearch rawInput={rawInput} />
        </Grid>

        <Grid item xs={4}>
          <Color bevQuotation={bevQuotation} />
        </Grid>

        <Zone bevQuotation={bevQuotation} />

        <Grid item xs={5}>
          <Domain bevQuotation={bevQuotation} />
        </Grid>

        <Grid item xs={7}>
          <Cuvee bevQuotation={bevQuotation} />
        </Grid>

        <Grid item xs={4}>
          <Vintage bevQuotation={bevQuotation} />
        </Grid>

        <Grid item xs={4}>
          <Format bevQuotation={bevQuotation} />
        </Grid>

        <Grid item xs={4}>
          <Conditioning bevQuotation={bevQuotation} />
        </Grid>
      </Grid>
    </PaddingPaper>
  )
}

export default connect(bevQuotationFromId)(QuotationCorrectionForm)
