import { Typography } from "@material-ui/core"
import styled from "styled-components"

const ClampedTypo = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
`

export default ClampedTypo
