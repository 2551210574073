import React from "react"
import { connect } from "react-redux"
import { VintageCell } from "../TableCell"
import { modifyBevQuotationVintage } from "ducks/bevProductData/vintage"
import { useUri } from "utils/router"
import VintageSelect from "components/Select/VintageSelect"

export const Vintage = ({ bevQuotation, modifyBevQuotationVintage }) => {
  const { currentQuotationId } = useUri()
  const {
    bevProductData: { vintage = "" },
    id,
  } = bevQuotation
  return (
    <VintageCell>
      <VintageSelect
        data-testid={`vintageSelect-${id}`}
        value={vintage}
        onChange={({ target: { value: vintage } }) =>
          modifyBevQuotationVintage({
            currentQuotationId,
            bevQuotation,
            vintage,
          })
        }
        itemTestId="vintageItem"
        preventDefault
      />
    </VintageCell>
  )
}

export default connect(null, { modifyBevQuotationVintage })(Vintage)
