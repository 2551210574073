import React from "react"
import styled from "styled-components"
import { Button, Typography } from "@material-ui/core"
import t from "i18n"
import { Link } from "react-router-dom"
import { getUri, Path } from "../../utils/router"

const FlexContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`

const StyledButton = styled(Button)`
  padding: 20px 20px;
  min-width: 220px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.12);
`

const CreateQuotation = () => {
  return (
    <FlexContainer>
      <StyledButton
        variant="outlined"
        component={Link}
        to={getUri({ path: Path.newQuotation })}
      >
        <Typography variant="button">{t("listingCTA")}</Typography>
      </StyledButton>
    </FlexContainer>
  )
}

export default CreateQuotation
