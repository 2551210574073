import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import { immutableSet } from "utils/reduxUtils"
import { setBevProductData } from "../actions"
import { setProductDataReducerFactory } from "../../../utils/bevProductData"

const {
  reducer,
  actions: { modifyBevQuotationConditioning },
} = createSlice({
  name: "conditioning",
  initialState: null,
  reducers: {
    modifyBevQuotationConditioning: {
      reducer: _.identity,
      prepare: ({ bevQuotation, conditioning, currentQuotationId }) => ({
        payload: {
          currentQuotationId,
          bevQuotation: immutableSet(
            bevQuotation,
            "bevProductData.productInfo.conditioning",
            conditioning,
          ),
        },
      }),
    },
  },
  extraReducers: {
    [setBevProductData]: setProductDataReducerFactory(
      "productInfo.conditioning",
    ),
  },
})

export { modifyBevQuotationConditioning }

export default reducer
