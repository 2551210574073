import React from "react"
import { getFieldValue } from "utils/fieldUtils"
import GenericAutoComplete from "./GenericAutoComplete"
import { connect } from "react-redux"
import { setBevProductData } from "ducks/bevProductData/actions"
import _ from "lodash"
import { useUri } from "utils/router"

const findValueInList = ({ value, list }) => _.find(list, { name: value })

const FieldAutoComplete = ({
  field,
  fieldValue,
  label,
  closeFieldList,
  fieldName,
  setBevProductData,
  bevQuotation,
  fetchListOnFocus,
  getFieldList,
  shouldFetchList,
  openFieldList,
  required,
}) => {
  const { list, isOpen } = field
  const { currentQuotationId } = useUri()
  const onChange = (e, value) => {
    const fieldValue = value?.id ? value : findValueInList({ value, list })

    return setBevProductData({
      fieldName,
      ...(fieldValue && { fieldValue }),
      bevQuotation,
      currentQuotationId,
    })
  }

  return (
    <GenericAutoComplete
      fullWidth
      field={field}
      fieldValue={fieldValue}
      label={label}
      onChange={onChange}
      required={required}
      open={isOpen}
      onOpen={e => {
        openFieldList()
        if (shouldFetchList && (!required || e.target.value))
          getFieldList({ fieldName: getFieldValue(e.target.value) })
      }}
      onInputChange={(e, value, reason) => {
        value &&
          isOpen &&
          shouldFetchList &&
          reason !== "reset" &&
          getFieldList({ [fieldName]: getFieldValue(value) })
      }}
      onFocus={
        fetchListOnFocus &&
        (e => {
          openFieldList()
          shouldFetchList &&
            getFieldList({
              [fieldName]: getFieldValue(e.target.value),
            })
        })
      }
      onClose={() => closeFieldList()}
    />
  )
}

export default connect(null, { setBevProductData })(FieldAutoComplete)
